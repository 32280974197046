<template>
    <section class="main-layout">
            <div class="header-wrapper">
                <div class="container">
                    <Header/>
                </div>
            </div>
            <div class="content-wrapper">
                <div class="container">
                    <router-view></router-view>
                </div>
            </div>
    </section>
    <div 
        v-show="queue?.length"
        ref="appAudioPlayer" 
        class="audio-player-wrapper"
        @mousedown="onMouseDown"
        :style="`
            bottom: ${bottom}px;
            left: ${left}px;
        `"
    >
        <AudioPlayer
            id="app-audio-player"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import AudioPlayer from '@/components/AudioPlayer.vue'
import Header from '@/sections/Header.vue'

export default {
    components:{
        Header,
        AudioPlayer,
    },

    data(){
        return{
            audioPlayerElem: null,
            windowMouseMoveListener: null,
            bottom: 24,
            left: 24,
        }
    },

    mounted(){
        this.fetchUserData()
        this.initPlayer();
    },

    computed:{
        ...mapGetters(['queue']),
    },

    methods:{
        ...mapActions(['fetchUserData']),

        onMouseDown(){
            this.windowMouseMoveListener = window.addEventListener('mousemove', this.changePos)

            window.addEventListener('mouseup',() => {
                window.removeEventListener('mousemove',this.changePos)
            })
        },

        initPlayer() {
            const savedPosition = localStorage.getItem('playerPosition');
            if (savedPosition) {
                const position = JSON.parse(savedPosition);
                this.left = position.left;
                this.bottom = position.bottom;
            }
        },

        changePos(event) {
            const { clientWidth, clientHeight } = document.documentElement;
            const playerWidth = this.$refs.appAudioPlayer.clientWidth;
            const playerHeight = this.$refs.appAudioPlayer.clientHeight;

            this.left += event.movementX;
            this.bottom -= event.movementY;

            this.left = Math.max(0, this.left);
            this.bottom = Math.max(0, this.bottom);

            const maxHeight = clientHeight - playerHeight;
            const maxWidth = clientWidth - playerWidth;
            this.left = Math.min(this.left, maxWidth);
            this.bottom = Math.min(this.bottom, maxHeight);

            const position = { left: this.left, bottom: this.bottom };
            localStorage.setItem('playerPosition', JSON.stringify(position));
        },
    },
}
</script>

<style lang="scss" scoped>

    .main-layout{
        height: 100vh;
        min-height: 100vh;
    }

    .header-wrapper{
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(31, 32, 35, .3);
        backdrop-filter: blur(3px);
        z-index: 10;
    }
    .content-wrapper{
        height: 100%;
    }
    .container{
        height: 100%;
        width: 1400px;
        margin: 0 auto;
    }

    #app-audio-player{
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .audio-player-wrapper {
        position: fixed;
        z-index: 99999;
    }
</style>