import { createStore } from 'vuex'
import axios from 'axios'
import ArtistModule from './modules/artist'
import AudioPlayerModule from './modules/audioPlayer'
import ReleasesModule from './modules/releases'
import FilesModule from './modules/files'
import UsersModule from './modules/users'
import PurchasesModule from './modules/purchases'
import SurveyModule from './modules/survey'
import isAdmin from '@/utils/isAdmin'
import getParsedToken from '@/utils/getParsedToken'

async function getFingerprint () {
    const userAgent = `${navigator.hardwareConcurrency} ${navigator.maxTouchPoints} ${navigator.userAgent}`;

    let fingerprint = userAgent;

    if (crypto.subtle) {
        const msgUint8 = new TextEncoder().encode(userAgent);
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        fingerprint = await hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    }

    return fingerprint;
}

// Create a new store instance.
const store = createStore({
    state () {
        return {
            userData:null,
            profiles: [],
            credentials: [],
            tickets: [],
            musicStyles: [],

            composerRoles: [],

            pendingUpdate_RefreshTokens: null
        }
    },
    actions: {
        /* AUTH */
        async login({dispatch}, user_data){
            const fingerprint = await getFingerprint();
            return new Promise( (resolve, reject) => {
                axios.post('/login',{
                    username: user_data.username,
                    password: user_data.password,
                    fingerprint
                }).then(response => {
                    const accessToken = response.data.access_token;
                    const { role } = getParsedToken(accessToken);
                    if (!isAdmin(role)) {
                        window.location.href = 'https://pro.mylabel.cc/';
                        reject();
                    } else {
                        localStorage.setItem('access_token', accessToken);
                        dispatch('fetchUserData')
                        resolve(response.data)
                    }
                }).catch(error => reject(error))
            })
        },

        fetchUserData({commit}){
            return new Promise( (resolve, reject) => {
                axios.get('/me').then(response => {
                    let accessToken = localStorage.getItem('access_token');
                    let tokenPayload = JSON.parse(atob(accessToken.split('.')[1]));
                    commit('updateUserData',{
                        uuid: tokenPayload.sub,
                        username: tokenPayload.username,
                        is_verified: tokenPayload.is_verified,
                        role: tokenPayload.role
                    })
                    resolve(response.data)
                }).catch(error => reject(error))
            })
        },

        logout({commit}){
            commit('clearUserData')

            return new Promise( (resolve, reject) => {
                axios.post('/logout').then(response => resolve(response)).catch(error => reject(error))
            })
        },

        async refresh({commit}){
            console.log('store:: вызван refresh запрос')
            const fingerprint = await getFingerprint();
            const refreshPromise = new Promise((resolve, reject) => {
                axios.create().post('/refresh', { fingerprint }, { withCredentials: true }).then((response) => {
                    console.log('store:: новые токены получены')
                    localStorage.setItem('access_token', response.data.access_token);
                    resolve(response);
                }).catch(error => {
                    console.log('store:: ошибка новые не токены получены')
                    console.log(error)
                    commit('clearUserData');
                    reject(error);
                }).finally(() => {
                    console.log('store:: удаляем слушатель этого запроса')
                    commit('unsubscribeRefreshToken');
                });
            });
            console.log('store:: создаём слушатель данного запросаы')
            commit('subscribeRefreshToken', refreshPromise);
            return refreshPromise;
        },

        

        /* PROFILES */
        fetchProfileByCredential({commit}, credentialUuid){
            return new Promise( (resolve, reject) => {
                axios.get(`/profile/by_credentials/${credentialUuid}`).then( response => {
                    resolve(response.data)
                    commit('updateProfile', response.data)
                }).catch(e => reject(e))
            })
        },

        /* PROFILES */
        fetchCredential({commit}, credentialUuid){
            return new Promise( (resolve, reject) => {
                axios.get(`/credential/${credentialUuid}`).then( response => {
                    resolve(response.data)
                    commit('updateCredential', response.data)
                }).catch(e => reject(e))
            })
        },

        /* TICKETS */
        fetchTickets: function ({commit}){
            return new Promise((resolve,reject) => {
                axios.get('/tickets').then(response => {
                    commit('updateTickets', response.data.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        fetchTicketMessages: function({commit}, ticketUuid){
            return new Promise((resolve, reject) => {
                axios.get(`/ticket/${ticketUuid}/messages`).then(response => {
                    commit('setMessagesToTicket',{ticketUuid, messages:response.data.data})
                    resolve(response)
                }).catch(error => reject(error))
            })
        },

        setCloseStatusTicket({commit}, ticketUuid){
            return new Promise((resolve, reject) => {
                axios.patch(`/ticket/${ticketUuid}`,{status:'CLOSED'}).then(response => {
                    commit('updateTicketStatus',{ticketUuid,status:'CLOSED'})
                    resolve(response)
                }).catch(error => reject(error))
            })
        },

            /*
            data:{
                ticketUuid:<>
                message:{
                    content:<>,
                    attachments:[]
                }
            }
            */
        createTicketMessage({commit}, data){
            return new Promise((resolve, reject) => {
                axios.post(`/ticket/${data.ticketUuid}/message`,{content:data.message.content}, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                },).then(response => {
                    commit('addMessageToTicket',{ticketUuid:data.ticketUuid, message: response.data.data})
                    resolve(response)
                }).catch(error => reject(error))
            })
        },

        /* MUSIC STYLES */
        fetchMusicStyles({commit}){
            return new Promise((resolve,reject) => {
                axios.get('/music-styles').then(response => {
                    commit('replaceMusicStyles', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    mutations: {
        /*MUSIC STYLES */
        replaceMusicStyles(state, musicStyles){
            state.musicStyles = musicStyles
        },

        /* AUTH */
        updateUserData(state, userData){
            state.userData = userData
        },

        subscribeRefreshToken: function (state, promise) {
            state.pendingUpdate_RefreshTokens = promise;
        },

        unsubscribeRefreshToken: function (state) {
            state.pendingUpdate_RefreshTokens = null;
        },

        clearUserData(state){
            state.userData = null
            localStorage.clear()
        },

        /*PROFILES*/
        updateProfiles(state, profiles){
            state.profiles = profiles
        },

        updateProfile(state, profile){
            let index = state.profiles.findIndex(user => user.credentials_uuid == profile.credentials_uuid)
            if(index > -1)
                state.profiles[index] = profile
            else
                state.profiles.push(profile)
        },

        /*CREDENTIALS*/
        updateCredentials(state, credentials){
            state.credentials = credentials
        },

        updateCredential(state, newCredential){
            let index = state.credentials.findIndex(credential => credential.uuid == newCredential.uuid)
            if(index > -1)
                state.credentials[index] = newCredential
            else
                state.credentials.push(newCredential)
        },

        /* TICKETS */
        updateTickets(state, tickets){
            state.tickets = tickets
        },

            /*
            data:{
                ticketUuid:<>
                messages:[]
            }
            */
        setMessagesToTicket(state, data){
            let index = state.tickets.findIndex(ticket => ticket.uuid == data.ticketUuid)
            if(index > -1)
                state.tickets[index].messages = data.messages
        },

            /*
            data:{
                ticketUuid:<>
                status:<OPEN,PENDING,CLOSE>
            }
            */
        updateTicketStatus(state, data){
            let index = state.tickets.findIndex(ticket => ticket.uuid == data.ticketUuid)
            if(index > -1)
                state.tickets[index].status = data.status
        },

        addMessageToTicket(state,data){
            let index = state.tickets.findIndex(ticket => ticket.uuid == data.ticketUuid)
            if(index > -1)
                state.tickets[index].messages.push(data.message)
        }
    },
    getters: {
        /* AUTH */
        pendingUpdate_RefreshTokens: state => state.pendingUpdate_RefreshTokens,

        getUserData: state => state.userData,

        /* PROFILES */
        getAllProfiles: state => state.profiles,

        getProfile: state => uuid => state.profiles.find(user => user.credentials_uuid == uuid),

        /* CREDENTIALS */
        getAllCredentials: state => state.credentials,

        getCredential: state => uuid => state.credentials.find(credential => credential.uuid == uuid),

        /* TICKETS */
        getTickets: state => state.tickets,

        getTicket: state => uuid => state.tickets.find(ticket => ticket.uuid == uuid),

        /* MUSIC STYLES */
        getPrimaryMusicStyle: state => musicStyleId => state.musicStyles.find(style => style.musicStyleId == musicStyleId),

        getSecondaryMusicStyle: state => secondaryMusicStyleId => state.musicStyles.find(style => style.childs.find(childStyle => childStyle.musicStyleId == secondaryMusicStyleId)),

        getPrimaryMusicStyles: state => state.musicStyles,

        getSecondaryMusicStyles: state => {
            return state.musicStyles.map(style => style.childs).reduce((left_childs,right_childs) => [...left_childs,...right_childs]).map(style => style.name)
        },
    },
    modules:{
        ArtistModule,
        AudioPlayerModule,
        ReleasesModule,
        FilesModule,
        UsersModule,
        PurchasesModule,
        SurveyModule
    }
})

export default store