<template>
  <div class="loader-wrapper">
    <span v-bind="$attrs" class="loader"></span>
    <span v-if="tooltip" class="tooltip">{{ tooltip }}</span>
  </div>
</template>
<script>

export default {
  props:{
    icon: Object,
    tooltip: String
  },
};
</script>
<style lang="scss" scoped>
.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-wrapper{
  position: relative;
  .tooltip {
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 10;
    bottom: 125%; /* Position the tooltip above the button */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  
    &:after {
      content: "";
      position: absolute;
      top: 100%; /* Arrow pointing down */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
  
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes rotation {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
} 
</style>