import axios from 'axios';

const files = {
  state: () => ({
    files: [],
  }),
  actions: {
    // eslint-disable-next-line no-unused-vars
    uploadFile: function ({ commit }, { file, loadProgress }) {
      const formdata = new FormData();
      formdata.append('file', file);
      
      return new Promise((resolve, reject) => {
        axios.post('/file', formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => { loadProgress(parseInt(progressEvent.loaded / progressEvent.total * 100)); },
        }).then(response => {
          const base64 = window.URL.createObjectURL(file);
          const newFile = {
            base64,
            uuid: response.data.data.uuid,
            binary: file,
          };
          commit('addFile', newFile);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },

    fetchFile: function ({ commit, getters }, fileUuid) {
      if (!fileUuid) return;

      const file = getters.file(fileUuid)
      if (file) return file;

      return new Promise((resolve, reject) => {
        axios.get(`/file/${fileUuid}/download`, {
          responseType: 'blob',
        }).then(response => {
          const base64 = window.URL.createObjectURL(response.data);
          const file = {
            base64,
            uuid: fileUuid,
            binary: response.data,
          };
          commit('addFile', file);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    deleteFile: function ({ commit }, fileUuid) {
      return new Promise((resolve, reject) => {
        axios.delete(`/file/${fileUuid}`).then(response => {
          commit('removeFile', response.data.uuid);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
  },
  mutations: {
    addFile: function (state, file) {
      state.files.push(file);
    },
    removeFile: function (state, uuid) {
      state.files = state.files.filter((file) => file.uuid !== uuid);
    },
  },
  getters: {
    files: state => {
      return state.files;
    },

    file: state => fileUuid => {
      return state.files.find((file) => file.uuid == fileUuid);
    },
  },
};

export default files;
