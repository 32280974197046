<template>
    <div class="artist-item" v-if="artistData">
        <div class="avatar">
            <Avatar
                :src="
                    file(artistData.avatar_uuid)?.base64 ?? require('@/assets/user.png')
                "
            />
        </div>
        <div class="info" v-if="getArtistsRoles?.length">
            <span class="name">
                {{artistData.name ?? 'Неизвестен'}}
            </span>
            <div class="data">
                <p>{{artistData.role}}</p>
                <div class="icons">
                    <SvgIcon :size="16" type="mdi" class="icon" :path="icons.apple" />
                    <SvgIcon :size="16" type="mdi" class="icon" :path="icons.spotify" />
                </div>
            </div>
        </div>
        <div class="actions">
            <IconButton :icon="icons.info" @click="$emit('info',artistData.uuid)"/>
            <IconButton :icon="icons.pencil" @click="$emit('edit',artistData.uuid)"/>
        </div>
    </div>
    <div class="artist-skeleton loading" v-else>
        <div class="cover-skeleton loading"></div>
    </div>
</template>
<script>
import Avatar from '@/components/Avatar.vue'
// import Chip from './Chip.vue'
import IconButton from './IconButton.vue'
import SvgIcon from '@jamescoyle/vue-icon';

// import Select from '@/components/Select.vue'

import { mapActions, mapGetters } from 'vuex'
import { mdiPencil, mdiInformation, mdiSpotify, mdiApple } from '@mdi/js'


export default {
    components:{
        Avatar,
        // Chip,
        IconButton,
        SvgIcon
        // Select
    },
    props:{
        artist: Object,
        disabled: Boolean,
    },
    data(){
        return {
            artistData: null,
            icons:{
                pencil: mdiPencil,
                info: mdiInformation,
                spotify: mdiSpotify,
                apple: mdiApple
            }
        }
    },
    mounted(){
        this.artistData = {...this.artist}
        this.fetchArtistsRoles()
        this.fetchArtist(this.artist.artist_uuid).then(response => {
            this.artistData = {
                role: this.artist.role,
                uuid: this.artist.artist_uuid,
                name: response.data.name,
                spotify_id: response.data.spotify_id,
                apple_id: response.data.apple_id
            };
            this.fetchFile(response.data.avatar_uuid);
        })
    },
    methods:{
        ...mapActions([
            'fetchArtistsRoles',
            'fetchArtist',
            'fetchFile'
        ])
    },
    computed:{
        ...mapGetters([
            'getArtistsRoles',
            'getArtist',
            'file'
        ]),

        rolesDropdownItems(){
            const items = [...this.getArtistsRoles].map((role) => ({
                id: role,
                name: role,
            }));

            return items;
        }
    }
}
</script>
<style lang="scss" scoped>
    .artist-item{
        padding: 5px 10px;
        background: #1F2023;
        border-radius: 9px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width:  400px;

        .avatar{
            width: 45px;
            height: 45px;
            margin-right: 20px;
        }

        .info{
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-between;

            .name{
                width: 250px;
                max-width: 250px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .data{
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;

                .icons{
                    padding: 0px 10px;
                    .icon{
                        transform: translateY(2px);
                        opacity: .2;
                        margin-right: 5px;

                        &:last-child{
                            padding: none;
                        }
                    }
                }
            }

            .actions{
                display: flex;
            }
        }

    }

    .cover-skeleton{
        width: 45px;
        height: 45px;
        background: white;
        border-radius: 15px;
    }

    @keyframes loading {
        0% {
            background-position: -400px 0;
        }
        100% {
            background-position: 400px 0;
        }
    }

    .artist-skeleton {
        padding: 5px 10px;
        border-radius: 9px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 400px;
        height: 70px;
    }

    .loading{
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%);
        background-size: 800px 100%;
        animation: loading 1.5s infinite;
    }
</style>