<template>
    <div class="file-input" :class="type">
        <div class="type">
            {{ typeName }}
        </div>

        <label
            class="input"
            @dragover.prevent
            @dragenter.prevent
            @drop="handleDrop"
        >
            <span v-if="!fileSelected">
                Перетащите или выберите
            </span>
            <span class="name" v-else>
                {{ fileName }}
            </span>
            <input
                type="file"
                @change="handleFileInput"
                :accept="accept"
                ref="fileInput"
                style="display: none"
            />
        </label>
    </div>
</template>

<script>
export default {
    emits: ['select'],
    props: {
        type: {
            type: String,
            default: "image",
        },
    },
    data() {
        return {
            typeName: this.type === "image" ? "Изображение" : "Аудиофайл",
            fileSelected: false,
            file: null,
        };
    },
    computed: {
        fileName() {
            if (this.file) {
                return this.file.name;
            }
            return "";
        },
        accept() {
            if (this.type === "image") {
                return "image/*";
            }
            return "audio/*";
        },
    },
    methods: {
        dataURItoBlob (dataURI) {
            let byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(dataURI.split(',')[1]);
            } else {
                byteString = unescape(dataURI.split(',')[1]);
            }

            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            const ia = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], { type: mimeString });
        },
        handleFileInput(e) {
            const file = e.target.files[0];
            if (file) {
                this.fileSelected = true;
                this.file = file;
                this.readFile(file);
            } else {
                this.fileSelected = false;
                this.file = null;
            }
        },
        handleDrop(e) {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            if (file) {
                this.fileSelected = true;
                this.file = file;
                this.readFile(file);
            } else {
                this.fileSelected = false;
                this.file = null;
            }
        },
        readFile(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.$emit("select", {
                    binary: file,
                    base64: reader.result,
                });
            };
        },
        browseFiles() {
            this.$refs.fileInput.click();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";

.file-input {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid $dark-gray;
    border-radius: 11px;
    background: #1f2023;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;

    .type {
        padding: 10px 13px;
    }
    
    .input {
        display: flex;
        align-items: center;
        width: 70%;
        border-left: 1px dotted white;
        font-weight: bold;
        padding: 10px 13px;
        cursor: pointer;
        input[type="file"] {
            display: none;
        }

        &:hover {
            border-left: 1px solid white;
            background: #3f4146;
            border-radius: 0px 12px 12px 0px;
        }

        .name {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
    }
}
</style>
