<template>
    <button :class="[
        'button',
        {gray:$attrs.gray != undefined},
        {blue:$attrs.blue != undefined},        

        {large:$attrs.large != undefined},
        {small:$attrs.small != undefined},
        {round:$attrs.round != undefined},
        {outlined:$attrs.outlined != undefined},
        {loading:$attrs.loading != undefined}
    ]">
        <slot>button</slot>
        <div v-show="loading">
            <svg
                class="loading-icon"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 170 170"
                style="enable-background:new 0 0 519.9 130.4;"
                xml:space="preserve"
            >
                <g>
                    <path d="M30.2596 150.381C29.9596 150.381 29.5596 150.381 29.1596 150.281C25.2596 149.681 22.5596 146.081 23.0596 142.181L39.3596 26.1811C39.9596 22.2811 43.5596 19.4811 47.4596 20.0811C51.3596 20.6811 54.1596 24.2811 53.5596 28.1811L46.6596 77.6811C52.5596 67.5811 59.7596 57.4811 68.5596 48.2811C71.5596 45.1811 76.1596 44.2811 80.0596 46.2811C83.9596 48.1811 86.1596 52.3811 85.5596 56.6811C84.0596 67.0811 82.6596 76.6811 81.5596 84.8811C87.4596 77.5811 95.0596 70.2811 104.46 64.8811C107.76 62.9811 111.76 63.0811 114.96 65.2811C118.16 67.3811 119.76 71.0811 119.26 74.8811L117.76 85.3811C123.36 82.0811 130.16 79.1811 137.96 77.9811C141.86 77.3811 145.56 80.1811 146.16 84.0811C146.76 87.9811 143.96 91.6811 140.06 92.2811C130.86 93.5811 123.06 98.5811 118.26 102.581C115.06 105.181 110.66 105.581 107.06 103.581C103.46 101.581 101.56 97.5811 102.06 93.4811L103.56 82.8811C94.0596 90.6811 87.3596 100.581 83.0596 108.281C80.6596 112.581 75.8596 114.481 71.1596 112.981C66.5596 111.481 63.7596 106.981 64.4596 102.181C65.5596 94.3811 67.1596 83.1811 69.0596 69.8811C43.7596 103.381 37.2596 143.881 37.2596 144.281C36.8596 147.781 33.7596 150.381 30.2596 150.381Z" />
                </g>
            </svg>
        </div>
    </button>
</template>
<script>
export default {
    props:{
        loading: Boolean
    }
}
</script>
<style lang="scss" scoped>
    .button{
        padding: 10px 14px;
        border-radius: 4px;
        font-size: 16px;
        display: flex;
        align-items: center;
        min-width: 90px;
        justify-content: center;
        
        background: #ED2224;
        color: white;
        transition: .3s;
        border: none;
        font-weight: 500;
        cursor: pointer;

        &:disabled{
            background: #a7a7a7;
            color: white;
        }

        &:enabled:hover{
            background: #e74a4d;
        }

        &:enabled:hover{
            transform: translateY(3px);
        }

        .loading-icon{
            margin-left: 15px;
            width: 25px;
            height: 25px;
            stroke: white;
            stroke-width:10px;

            fill: none;

            path {
                stroke-dasharray: 650;
                stroke-dashoffset: -100;
                animation: draw 4s ease-in-out infinite;
            }
        }
    }

    .large{
        font-size: 18px;
        padding: 12px 20px;
    }

    .small{
        padding: 6px 15px;
        font-size: 15px;
    }

    .round{
        border-radius: 100px;
    }

    .outlined{
        outline: 1px solid white;
        outline-offset: -1px;
        background: transparent;
        transition: .1s;

        &:hover{
            background: transparent;
            outline-offset: 0px;
        }
    }

    /*TODO*/
    .loading{}

    .gray{
        background: #3f4146;

        &:enabled:hover{
            background: #61646a;
        }
    }

    .blue{
        background: #2A5CDB;

        &:enabled:hover{
            background: #3667e2;
        }
    }

    @keyframes draw {
        0% {
            stroke-dashoffset: -650;
        }

            50% {
            stroke-dashoffset: 650;
        }

            100% {
            stroke-dashoffset: -650;
        }
    }
</style>