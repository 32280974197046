import PurchaseType from '@/utils/PurchaseType';
import axios from 'axios';

const purchases = {
  state: () => ({
    usersPurchases: [],

    products: [],
    subscriptions: [],
  }),
  actions: {
    fetchUserPurchases({commit}, userUuid){
      const today = new Date();

      const startDate = new Date(today);
      startDate.setFullYear(today.getFullYear() - 3);

      const endDate = new Date(today);
      endDate.setFullYear(today.getFullYear() + 3);

      const params = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        order: 'DESC',
      };

      return new Promise( (resolve, reject) => {
        axios.get(`/user/${userUuid}/purchases`, { params }).then(response => {
          commit('setUserPurchases', response.data.data)
          resolve(response)
        }).catch(e => reject(e))
      })
    },

    updateUserProduct({commit}, { uuid, data }){
      return new Promise( (resolve, reject) => {
        axios.patch(`/user-product/${uuid}`, data).then(response => {
          const userPurchase = {
            ...response.data,
            type: PurchaseType.PRODUCT,
          };
          commit('updateUserPurchase', userPurchase)
          resolve(response)
        }).catch(e => reject(e))
      })
    },
    updateUserSubscription({commit}, { uuid, data }){
      return new Promise( (resolve, reject) => {
        axios.patch(`/user-subscription/${uuid}`, data).then(response => {
          const userPurchase = {
            ...response.data,
            type: PurchaseType.SUBSCRIPTION,
          };
          commit('updateUserPurchase', userPurchase)
          resolve(response)
        }).catch(e => reject(e))
      })
    },

    issueUserProduct({commit}, payload){
      return new Promise( (resolve, reject) => {
        axios.post('/user/product', payload).then(response => {
          const userPurchase = {
            ...response.data,
            type: PurchaseType.PRODUCT,
          };
          commit('addUserPurchase', userPurchase)
          resolve(response)
        }).catch(e => reject(e))
      })
    },
    issueUserSubscription({commit}, payload){
      return new Promise( (resolve, reject) => {
        axios.post('/user/subscription', payload).then(response => {
          const userPurchase = {
            ...response.data,
            type: PurchaseType.SUBSCRIPTION,
          };
          commit('addUserPurchase', userPurchase)
          resolve(response)
        }).catch(e => reject(e))
      })
    },

    fetchProducts({commit}){
      return new Promise( (resolve, reject) => {
        axios.get('/products').then(response => {
          commit('setProducts', response.data)
          resolve(response)
        }).catch(e => reject(e))
      })
    },
    fetchSubscriptions({commit}){
      return new Promise( (resolve, reject) => {
        axios.get('/subscriptions').then(response => {
          commit('setSubscriptions', response.data)
          resolve(response)
        }).catch(e => reject(e))
      })
    },
  },
  mutations: {
    setProducts(state, products){
      state.products = products;
    },
    setSubscriptions(state, subscriptions){
      state.subscriptions = subscriptions;
    },

    setUsersPurchases(state, purchases){
      state.usersPurchases = purchases;
    },
    setUserPurchases(state, purchases){
      state.usersPurchases.unshift(purchases);
    },
    addUserPurchase(state, newPurchase) {
      const userUuid = newPurchase.userUuid;
    
      const userPurchasesIndex = state.usersPurchases.findIndex(purchases => purchases.length > 0 && purchases[0].userUuid == userUuid);
    
      if (userPurchasesIndex !== -1) {
        state.usersPurchases[userPurchasesIndex].unshift(newPurchase);
      } else {
        state.usersPurchases.unshift([newPurchase]);
      }
    },    
    updateUserPurchase(state, purchaseToUpdate) {
      const userUuid = purchaseToUpdate.userUuid;
    
      const userPurchasesIndex = state.usersPurchases.findIndex(purchases => purchases.length > 0 && purchases[0].userUuid == userUuid);
      if (userPurchasesIndex !== -1) {
        const specificPurchaseIndex = state.usersPurchases[userPurchasesIndex].findIndex(purchase => purchase.uuid === purchaseToUpdate.uuid);
        if (specificPurchaseIndex !== -1) {
          state.usersPurchases[userPurchasesIndex][specificPurchaseIndex] = purchaseToUpdate;
        } else {
          state.usersPurchases[userPurchasesIndex].unshift(purchaseToUpdate);
        }
      } else {
        state.usersPurchases.unshift([purchaseToUpdate]);
      }
    }
  },
  getters: {
    usersPurchases: state => state.usersPurchases,
    userPurchases: state => uuid => state.usersPurchases.find(purchase => purchase[0]?.userUuid == uuid) ?? [],

    products: state => state.products,
    subscriptions: state => state.subscriptions,
  },
};

export default purchases;
