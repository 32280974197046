<template>
    <section class="content-container">
            <div class="tools-wrapper">
                <div class="header-wrapper">
                  <Header>Опросы</Header>
                  <Button small gray @click="openCreateSurveyModal">Создать <SvgIcon type="mdi" :path="icons.plus" /></Button>
                </div>
                <div class="tools">
                    <Search placeholder="Поиск" class="tools__wide tool"/>
                </div>
            </div>
            <div class="list">
              <div class="page__loader" v-if="isLoadingSurveys">
                  <LoadingLogo />
              </div>
              <div class="users__empty" v-else-if="!surveys.length">
                <h3>Опросы не найдены</h3>
              </div>
              <div v-else class="table-wrapper">
                <Table >
                  <table>
                      <thead>
                        <th>
                            SLUG
                        </th>
                        <th>Название</th>
                        <th>Дата активации</th>
                        <th>Истекает</th>
                        <th>
                            <button :class="['table-sortable',{'table-sortable-desc':pagination.options.order == 'DESC'},{'table-sortable-asc':pagination.options.order == 'ASC'}]" @click="toggleSort()">
                              Создан
                            </button>
                        </th>
                        <th>Действия</th>
                      </thead>
                      <tbody>
                        <tr v-for="survey in filteredSurveys" :key="survey.uuid" @click="$router.push({name: 'SurveyAnswers', params:{survey_uuid: survey.uuid}})">
                          <td>
                            <Chip small :color="survey.slug?'green':'gray'">
                              {{survey.slug?survey.slug:'None'}}
                            </Chip>
                          </td>
                          <td>
                            <div class="text-wrapper">
                              {{survey.name}}
                            </div>
                          </td>
                          <td>
                            <Chip small :color="survey.activatedAt?'green':'gray'">
                              {{survey.normalaizedActivatedDate}}
                            </Chip>
                          </td>
                          <td>
                            <Chip small :color="getExpiredDateColor(survey.expiredAt)">
                              {{survey.normalaizedExpiredDate}}
                            </Chip>
                          </td>
                          <td>
                            <Chip small>
                              {{survey.normalaizedCreatedDate}}
                            </Chip>
                          </td>
                          <td>
                            <div class="table-actions" @click.stop>
                              <IconButton @click="selectSurvey(survey.uuid)" :tooltip="'Редактировать'" :icon="icons.edit" />
                              <IconButton @click="$router.push({name: 'SurveyAnswers', params:{survey_uuid: survey.uuid}})" :tooltip="'Ответы'" :icon="icons.chart" />
                              <IconButton @click="deleteSurvey(survey)" :tooltip="'Удалить'" :icon="icons.delete" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
                </Table>
                <Pagination
                  :options="pagination.options"
                  :meta="pagination.meta"
                  @options-change="(newOptions) => { pagination.options = newOptions }"
                />
              </div>
            </div>
    </section>

    <CreateSurveyModal @create="createSurveyHandler" @close="isOpenCreateSurveyModal = false" :z-index="100" v-if="isOpenCreateSurveyModal"/>
    <UpdateSurveyModal :survey="selectedSurvey" v-if="selectedSurvey && !isSurveyFetchingLoading" @close="closeUpdateSurveyModalHadnler"/>
    <Modal v-show="isSurveyFetchingLoading">
      <div class="loading-survey">
        <LoadingLogo/>
      </div>
    </Modal>
    <DeleteSurveyModal v-if="deleteSelectedSurvey && isConfirmDeleteSurveyModal" :survey="deleteSelectedSurvey" @delete="deleteSurveyHandler" @close="isConfirmDeleteSurveyModal = false; deleteSelectedSurvey = null" />
</template>

<script>
import {mapActions,  mapGetters} from 'vuex'
import Header from '@/components/Header.vue'
import Search from '@/components/Search.vue'
import Modal from '@/components/Modal.vue'
import Pagination from '@/components/Pagination.vue'
import Button from '@/components/Button.vue'
import LoadingLogo from '@/components/LoadingLogo.vue'
// import Alert from '@/components/Alert.vue'
// import Input from '@/components/Input.vue'
// import Select from '@/components/Select.vue'
import Chip from '@/components/Chip.vue'
// import SelectButtons from '@/components/SelectButtons.vue'
import SvgIcon from '@jamescoyle/vue-icon';
import Table from '../components/Table.vue'
import { mdiClockRemove, mdiPencil, mdiDelete, mdiChartAreaspline, mdiPlus } from '@mdi/js'

import CreateSurveyModal from '@/components/survey/CreateSurveyModal.vue'
import UpdateSurveyModal from '@/components/survey/UpdateSurveyModal.vue'
import DeleteSurveyModal from '@/components/survey/DeleteSurveyModal.vue'
import IconButton from '@/components/IconButton.vue'

export default {
    components:{
        Header,
        Search,
        Modal,
        Pagination,
        Button,
        LoadingLogo,
        // Alert,
        // Input,
        // Select,
        Chip,
        // SelectButtons,
        SvgIcon,
        Table,
        CreateSurveyModal,
        UpdateSurveyModal,
        DeleteSurveyModal,
        IconButton
    },

    data(){
        return {
          isSurveyFetchingLoading: false,
          isLoadingSurveys: false,
          paginationParamsParsed: false,
          isOpenCreateSurveyModal: false,
          openUpdateSurveyModal: true,
          selectedSurvey: null,
          deleteSelectedSurvey: null,
          isConfirmDeleteSurveyModal: false,
          pagination: {
            options: {
              page: 1,
              take: 20,
              search: '',
              order: 'DESC'
            },
            meta: {},
          },
          icons:{
            clock: mdiClockRemove,
            edit: mdiPencil,
            delete: mdiDelete,
            chart: mdiChartAreaspline,
            plus: mdiPlus
          }
        }
    },

    async mounted(){
      this.parsePaginationOptions()
      this.fetchAllSurveysRequest()
    },

    computed:{
      ...mapGetters(['surveys']),

      filteredSurveys(){
        return [...this.surveys].map(survey => (
          {
            ...survey, 
            normalaizedExpiredDate: survey.expiredAt ? new Date(survey.expiredAt).toLocaleDateString('ru-RU') : 'Никогда', 
            normalaizedCreatedDate: new Date(survey.createdAt).toLocaleDateString('ru-RU'),
            normalaizedActivatedDate: survey.activatedAt ? new Date(survey.activatedAt).toLocaleDateString('ru-RU') : 'Никогда'
          }
        ))
      }
    },

    methods:{
      ...mapActions(['fetchAllSurveys', 'fetchSurvey', 'updateSurvey']),

      deleteSurvey(survey){
        this.isConfirmDeleteSurveyModal = true
        this.deleteSelectedSurvey = survey;
      },

      deleteSurveyHandler(){
        this.isConfirmDeleteSurveyModal = false,
        this.deleteSelectedSurvey = null
      },

      selectSurvey(surveyUuid){
        this.$router.push({name:'Survey', params:{survey_uuid: surveyUuid}})
        this.isSurveyFetchingLoading = true
        this.fetchSurvey(surveyUuid).then(response => {
          this.selectedSurvey = response.data
        }).finally(() => {
          this.isSurveyFetchingLoading = false
        })
      },

      closeUpdateSurveyModalHadnler(){
        this.selectedSurvey = null
        this.setQueryParams()
      },

      createSurveyHandler(){
        this.isOpenCreateSurveyModal = false
      },

      openCreateSurveyModal(){
        this.isOpenCreateSurveyModal = true
      },

      getExpiredDateColor(datestring){
        const date = new Date(datestring)
        const currentDate = new Date()
        return currentDate > date ? 'red' : 'green' 
      },

      getActivatedColor(datestring){
        const date = new Date(datestring)
        const currentDate = new Date()
        return currentDate < date ? 'red' : 'green'
      },

      fetchAllSurveysRequest () {
          this.isLoadingSurveys = true;
          this.fetchAllSurveys(this.pagination.options).then((response) => {
              this.pagination.meta = response.data.meta;
              if(this.pagination.meta.page > response.data.meta.pageCount){
                this.pagination.meta.page = response.data.meta.pageCount
              }
          }).finally(() => {
              this.isLoadingSurveys = false;
          });
      },

      parsePaginationOptions() {
        let queryString = window.location.search;
        let queryParams = {};

        if (queryString.charAt(0) === '?') {
            queryString = queryString.slice(1);
        }

        let paramsArray = queryString.split('&');

        paramsArray.forEach(param => {
            let [key, value] = param.split('=');
            queryParams[key] = decodeURIComponent(value);
        });

        // Перебираем исходные параметры и обновляем их значения, если они присутствуют в URL-адресе
        for (let key in this.pagination.options) {
            if (key in queryParams) {
                this.pagination.options[key] = queryParams[key];
            }
        }

        this.paginationParamsParsed = true
      },

      setQueryParams(){
        let currentUrl = window.location.origin + window.location.pathname;

        let query_params = Object.keys(this.pagination.options).map((key,index) => {
            if(this.pagination.options[key] !== undefined){
                const value = this.pagination.options[key];
                return `${key}=${value}${Object.keys(this.pagination.options).length-1 == index ? '' : '&'}`
            }
        }).join('');

        let separator = currentUrl.includes('?') ? '&' : '?';

        let newUrl = currentUrl + separator + query_params;

        window.history.pushState({path:newUrl},'',newUrl);

        this.fetchAllSurveysRequest();
      }
    },

    watch:{
      'pagination.options': {
        deep: true,
        immediate: true,
        handler () {
          if(this.paginationParamsParsed){
            this.setQueryParams()
            
          }
        },
      },
    }
}
</script>

<style lang="scss">
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";

.loading-survey{
  display: flex;
  justify-content: center;
  justify-items: center;
}

.text-wrapper{
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.page__loader{
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container{
  padding-top: 90px;
  color: $white;
  display: flex;
  position: relative;

  .header-wrapper{
    display: flex;
    justify-content: space-between;
    margin-right: 25px;
    padding: 10px 0px;
  }

  .tools {
    padding-top: 30px;
    height: min-content;
    width: 480px;
    margin-right: 25px;
    display: flex;

    .create-btn{
      height: 52px;
      padding: 0;
      margin-left: 10px;
    }

    .tool {
      margin-bottom: 20px;
    }

    &__filters {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 8px 0 24px;
    }
  }

  &__loading{
    flex: 1;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }

  .list{
    flex-grow: 1;
    height: 100%;
    // padding-top: 150px;
  }
}
</style>
