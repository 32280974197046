<template>
    <div class="track">
        <div class="preview">
            {{track.title}}
            
            <div class="actions-wrapper">
                <span v-if="!isEditMode" class="duration">3:31</span>
                <button 
                    @click="startPlayMode"
                    v-show="!isPlayMode"
                    :class="['btn' ,{'non-hidden':isEditMode}]"
                >
                    <img src="@/assets/icons/play.svg" alt="">
                </button>
                <button 
                    @click="endPlayMode"
                    v-show="isPlayMode"
                    :class="['btn' ,{'non-hidden':isEditMode}]"
                >
                    <img src="@/assets/icons/pause.svg" alt="">
                </button>
                <button 
                    :class="['btn' ,{'non-hidden':isEditMode}]" 
                    @click="startEditMode"
                    v-if="!isEditMode"
                >
                    <img src="@/assets/icons/edit.svg" alt="">
                </button>
                <button 
                    @click="onDeleteTrack"
                    v-show="!isEditMode"
                    class="btn"
                >
                    <img src="@/assets/icons/close.svg" alt="">
                </button>
                <button 
                    class="btn" 
                    @click="onSaveTrack"
                    v-if="isEditMode"
                >
                    <img src="@/assets/icons/success.svg" alt="">
                    <span>
                        Сохранить
                    </span>
                </button>
            </div>

        </div>
        <div class="info" v-show="isEditMode">
            <div class="row">
                <div class="item">
                    <h3>Название трека</h3>
                    <Input 
                        :placeholder="'Введите название трека'"
                        v-model="trackData.title"
                    />
                </div>
                <div class="item">
                    <h3>Аудиофайл</h3>
                    <FileInput
                        :type="'audio'"
                        @select="onFileSelect"
                    />
                </div>
            </div>

            <h3> Артисты </h3>
            <div class="list" v-if="trackData.artists.length">
                <Artist
                    class="item"
                    v-for="artist in trackData.artists"
                    :key="artist.uuid"
                    :artist="artist"
                    disabled
                />
            </div>
            <h4 v-else>у данного трека нет артистов!</h4>

            <h3> Авторы </h3>
            <Table v-if="trackData.composers.length">
                <table>
                    <thead>
                        <th>Имя</th>
                        <th>Фамилия</th>
                        <th>Отчество</th>
                        <th>Роль</th>
                        <th>Действия</th>
                    </thead>
                    <tbody>
                        <tr 
                            :class="[{'edit-mode':composer.actionStatus == 'ready-to-delete' || composer.actionStatus == 'delete'}]"
                            v-for="composer in trackData.composers" 
                            :key="composer.uuid"
                        >
                            <td>
                                <span v-if="composer.actionStatus != 'edit'" v-show="composer.name.length">{{composer.name}}</span>
                                <Chip v-if="composer.actionStatus != 'edit'" v-show="!composer.name.length" small :color="'gray'">Неизвестно</Chip>
                                <!-- edit mode -->
                                <Input 
                                    v-if="composer.actionStatus == 'edit'" 
                                    v-model.trim="composer.name" 
                                    :placeholder="'Имя автора'" 
                                    :error="composer.name.length < 2 || composer.name.length > 255"
                                    :disabled="composer.actionStatus == 'loading'"
                                />
                            </td>
                            <td>
                                <span v-if="composer.actionStatus != 'edit'" v-show="composer.surname.length">{{composer.surname}}</span>
                                <Chip v-if="composer.actionStatus != 'edit'" v-show="!composer.surname.length" small :color="'gray'">Неизвестно</Chip>
                                <!-- edit mode -->
                                <Input 
                                    v-model.trim="composer.surname" 
                                    :placeholder="'Фамилия автора'" 
                                    v-if="composer.actionStatus == 'edit'" 
                                    :error="composer.name.length < 2 || composer.name.length > 255"
                                    :disabled="composer.actionStatus == 'loading'"
                                />
                            </td>
                            <td>
                                <span v-if="composer.actionStatus != 'edit'" v-show="composer.patronymic.length">{{composer.patronymic}}</span>
                                <Chip v-if="composer.actionStatus != 'edit'" v-show="!composer.patronymic.length" small :color="'gray'">Неизвестно</Chip>
                                <!-- edit mode -->
                                <Input 
                                    v-model.trim="composer.patronymic" 
                                    :placeholder="'Отсчество автора'" 
                                    v-if="composer.actionStatus == 'edit'" 
                                    :error="composer.patronymic.length != 0 && (composer.patronymic.length < 2 || composer.patronymic.length > 255 )"
                                    :disabled="composer.actionStatus == 'loading'"
                                />
                            </td>
                            <td>
                                <Chip v-if="composer.actionStatus != 'edit'" small :color="'yellow'">{{composer.role}}</Chip>
                                <!-- edit mode -->
                                <Select
                                    v-if="composer.actionStatus == 'edit'"
                                    :items="composerRoles"
                                    v-model="composer.role"
                                    :placeholder="'Роль автора'"
                                    class="confirmation__input"
                                    :loading="isComposerRolesLoading"
                                    :disabled="composer.actionStatus == 'loading'"
                                />
                            </td>
                            <td>
                                <div class="table-actions">
                                    <IconButton
                                        v-show="!composer.actionStatus"
                                        @click="copyComposer(composer)"
                                        :tooltip="'Скопировать ФИО автора трека'" 
                                        :icon="icons.copy"
                                        :disabled="composer.actionStatus == 'loading'"
                                    />
                                    <IconButton
                                        v-show="!composer.actionStatus"
                                        @click="startEditComposer(composer)"
                                        :tooltip="'Редактировать автора трека'" 
                                        :icon="icons.edit"
                                        :disabled="composer.actionStatus == 'loading'"
                                    />
                                    <IconButton 
                                        v-show="!composer.actionStatus"
                                        @click="startDeleteComposer(composer)"
                                        :tooltip="'Удалить автора трека'" 
                                        :icon="icons.delete"
                                        :disabled="composer.actionStatus == 'loading'"
                                    />
                                    <!--edit mode-->
                                    <IconButton 
                                        v-show="composer.actionStatus == 'edit'"
                                        @click="editComposerRequest(composer)"
                                        :tooltip="'Сохранить'" 
                                        :icon="icons.save"
                                        :disabled="composer.actionStatus == 'loading'"
                                    />
                                    <!--delete mode-->
                                    <IconButton 
                                        v-show="composer.actionStatus == 'delete' || composer.actionStatus == 'ready-to-delete'"
                                        :disabled="composer.actionStatus != 'ready-to-delete' || composer.actionStatus == 'loading'"
                                        @click="deleteComposerRequest(composer)"
                                        :tooltip="'Подтвердить удаление'" 
                                        :icon="icons.deleteAlert"
                                    />
                                    <IconButton 
                                        v-show="composer.actionStatus == 'delete' || composer.actionStatus == 'ready-to-delete'"
                                        :disabled="composer.actionStatus == 'loading'"
                                        @click="resetComposerActionStatus(composer)"
                                        :tooltip="'Отменить удаление'" 
                                        :icon="icons.cancel"
                                    />
                                    <!--loading mode-->
                                    <DefaultLoader v-show="composer.actionStatus == 'loading'" style="width: 20px; height: 20px;" :tooltip="'Загрузка...'"/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Table>
            <h4 v-else>у данного трека нет авторов!</h4>

            <div class="row" v-if="getPrimaryMusicStyles.length">
                <div class="item">
                    <h3>Жанр</h3>
                    <Select 
                        :placeholder="'Выберите жанр'"
                        :items="getPrimaryMusicStyles.map(style => ({id: style.musicStyleId, name: style.name}))"
                        v-model="trackData.primary_music_style_id"
                    />
                </div>
                <div class="item">
                    <h3>Язык</h3>
                    <Select
                        :placeholder="'Выберите язык'"
                        :items="getLanguages.map(lang => ({id: lang.languageId, name: lang.name}))"
                        v-model="trackData.language_id"
                    />
                </div>
                <!-- <div class="item" v-if="getSecondaryMusicStyles.length">
                    <h3>Поджанр</h3>
                    <Select 
                        :placeholder="'Выберите поджанр'"
                        :items="getPrimaryMusicStyle(trackData.primary_music_style_id)?.childs.map(style => ({id: style.musicStyleId, name: style.name}))"
                        v-model="trackData.secondary_music_style_id"
                    />
                </div> -->
            </div>

            <div class="row">
                <div class="item">
                    <h3>Preview start</h3>
                    <Input 
                        :placeholder="'Введите секунды'"
                        type="number"
                        v-model="trackData.preview_start_seconds"
                    />
                </div>
            </div>
            <div class="row">
                <div class="item">
                    <h3>Explicit</h3>
                    <div class="item__switch">
                        <Switch v-model="trackData.explicit" />
                        <p>
                            {{ trackData.explicit ? 'Track is explicit' : 'Track isn\'t explicit' }}
                        </p>
                    </div>
                </div>
                <div class="item">
                    <h3>Instrumental</h3>
                    <div class="item__switch">
                        <Switch v-model="trackData.instrumental" />
                        <p>
                            {{ trackData.instrumental ? 'Instrumental' : 'Not instrumental' }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="item item--wide">
                    <h3>Lyrics</h3>
                    <Textarea 
                        :placeholder="'Текст песни'"
                        v-model="trackData.lyrics"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Artist from '@/components/Artist.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue' 
import FileInput from '@/components/FileInput'
import Textarea from '@/components/Textarea'
import Switch from '@/components/Switch'
import Table from '@/components/Table.vue'
import Chip from '@/components/Chip.vue'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import { mdiPencil, mdiDelete, mdiCheck, mdiContentCopy, mdiDeleteEmptyOutline, mdiClose } from '@mdi/js'
import IconButton from '@/components/IconButton.vue'
import DefaultLoader from '@/components/DefaultLoader.vue'

export default {
    components:{
    Input,
    Artist,
    Select,
    FileInput,
    Textarea,
    Switch,
    Table,
    Chip,
    IconButton,
    DefaultLoader
},
    props:{
        releaseUuid: String,
        track: Object
    },
    data(){
        return{
            trackData: {},
            newAudioFile: null,
            isEditMode: false,
            isComposerRolesLoading: false,
            icons:{
                copy: mdiContentCopy,
                edit: mdiPencil,
                delete: mdiDelete,
                deleteAlert: mdiDeleteEmptyOutline,
                save: mdiCheck,
                cancel: mdiClose
            }
        }
    },
    created(){
        this.trackData = {...this.track}
    },
    mounted(){
        this.isComposerRolesLoading = true
        this.fetchComposerRoles().finally(() => {
            this.isComposerRolesLoading = false
        })
    },
    methods:{
        ...mapActions([
            'fetchComposerRoles',
            'fetchMusicStyles',
            'updateTrack',
            'uploadFile',
            'editComposer',
            'deleteComposer'
        ]),

        ...mapMutations(['setIsPlaying']),

        startEditComposer(composer){
            composer.actionStatus = 'edit'
        },

        editComposerRequest(composer){
            composer.actionStatus = 'loading'
            this.editComposer({
                uuid: composer.uuid,
                name: composer.name,
                surname: composer.surname,
                patronymic: composer.patronymic || undefined,
                role: composer.role,
                trackUuid: this.trackData.uuid, 
                releaseUuid: this.releaseUuid
            }).finally(() => {
                this.resetComposerActionStatus(composer)
            })
        },

        startDeleteComposer(composer){
            composer.actionStatus = 'delete'
            setTimeout(() => {
                composer.actionStatus = 'ready-to-delete'
            },1500)
        },

        deleteComposerRequest(composer){
            if(composer.actionStatus == 'ready-to-delete'){
                composer.actionStatus = 'loading'
                this.deleteComposer({
                    uuid: composer.uuid,
                    trackUuid: this.trackData.uuid, 
                    releaseUuid: this.releaseUuid
                }).finally(() => {
                    const idx = this.trackData.composers.findIndex(trackComposer => trackComposer.uuid = composer.uuid)
                    if(idx != -1){
                        this.trackData.composers.splice(idx, 1)
                    }
                    this.resetComposerActionStatus(composer)
                })
            }
        },

        resetComposerActionStatus(composer){
            composer.actionStatus = null
        },

        startEditMode(){
            this.isEditMode = true;
        },

        startPlayMode(){
            this.$emit('play',this.track);
            this.setIsPlaying(true);
        },

        endPlayMode() {
            this.setIsPlaying(false);
        },

        uploadNewAudio () {
            const payload = {
                file: this.newAudioFile,
            };

            return this.uploadFile(payload).then((response) => {
                this.trackData.wav_uuid = response.data.uuid;
            });
        },

        async updateTrackRequest () {
            if (this.newAudioFile) {
                await this.uploadNewAudio();
            }

            const payload = {
                ...this.trackData,
                preview_start_seconds: parseInt(this.trackData.preview_start_seconds),
            };

            this.updateTrack(payload);
        },

        onSaveTrack(){
            this.isEditMode = false;
            this.updateTrackRequest();
        },

        onFileSelect ({ binary }) {
            this.newAudioFile = binary;
        },
    },
    computed:{
        ...mapGetters([
            'getPrimaryMusicStyle',
            'getSecondaryMusicStyle',
            'getPrimaryMusicStyles',
            'getSecondaryMusicStyles',
            'getLanguages',
            'getComposersRoles',
            'currentTrack',
            'isPlaying',
            'getArtist',
        ]),

        composerRoles (){
            const availableComposerRolesOfInstrumentalTrack = [
                'COMPOSER',
                'LYRICIST',
                'COMPOSER_AND_LYRICIST'
            ]
            const roles = [...this.getComposersRoles].filter(role => {
                return this.trackData.instrumental ? availableComposerRolesOfInstrumentalTrack.includes(role) : true
            }).map((role) => ({id: role, name: role }))

            return roles;
        },

        isPlayMode () {
            return (this.currentTrack?.uuid == this.trackData?.uuid) && this.isPlaying;
        },
    }
}
</script>
<style lang="scss" scoped>

    .edit-mode{
        background: red;
        animation: pulse 2s infinite;
    }

    @keyframes pulse {
        0% {
            background-color: transparent;
        }
        50% {
            background-color: rgba(red, .2); 
        }
        100% {
            background-color: transparent;
        }
    }

    .track{
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #1F2023;
        border-radius: 5px;
        margin-bottom: 15px;

        .table-actions{
            display: flex;
            align-items: center;
        }

        .preview{
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 50px;
            box-shadow: 2px 0px 10px black;
            box-sizing: border-box;

            .actions-wrapper{
                display: flex;
                align-items: center;

                .duration{
                    color: #82868A;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .btn{
                    cursor: pointer;
                    border: none;
                    display: flex;
                    min-width: 30px;
                    width: min-content;
                    color: white;
                    height: 30px;
                    padding: 5px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: transparent;
                    margin-left: 5px;
                    box-sizing: border-box;
        
                    img{
                        width: 20px;
                        height: 20px;
                    }
        
                    &:hover{
                        background: #61646a;
                        transition: .2s;
                    }

                    &:active{
                        transform: scale(.9);
                    }

                    span{
                        margin-left: 10px;
                    }
                }

                .non-hidden{
                    display: flex;
                }
            }
        }

        .info{
            padding: 20px 40px 40px 40px;
            width: 100%;

            .list{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                &--one_column{
                    flex-direction: column;
                    .item{
                        width: 100%;
                        margin-bottom: 10px;
                    }
                }

                .item{
                    width: 400px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;
                    background: #1F2023;
                    border-radius: 9px;
                    margin:0px 10px 10px 0px;
                }
            }

            h3{
                margin-bottom: 10px;
                text-transform: uppercase;
                font-size: 16px;
                color: #adb2b7;
            }

            .row{
                margin: 25px 0px;
                width: 100%;
                display: flex;
                justify-content: space-between;

                .item{
                    max-width: 50%;
                    flex-grow: 1;
                    margin-right: 10px;

                    &:last-child{
                        margin-right: 0;
                        margin-left: 10px;
                    }

                    &:nth-child(1n){
                        margin-left: 0;
                    }

                    &--wide {
                        max-width: 100%;
                    }

                    &__switch {
                        padding-left: 4px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        p, label {
                            margin: 0;
                        }
                    }
                }
            }
        }

    }
</style>