import { createApp } from 'vue'
import App from './App.vue'
import axios from './axios'
import VueAxios from 'vue-axios'
import router from './router/index.js'
import store from './store'
import 'normalize.css';
import 'vue3-toastify/dist/index.css';

import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const options = {
    autoClose: 3000,
    theme: 'dark'
};


const app = createApp(App)
app
    .use(Vue3Toastify, options)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .mount('#app')
