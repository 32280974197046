<template>
    <div :class="[
        'alert',
        { gray: $attrs.gray !== undefined },
        { blue: $attrs.blue !== undefined }
    ]">
        <div class="wrapper">
            <slot>Alert!</slot>
            <button @click="showMessage = !showMessage" v-show="message">{{showMessage? messageInactiveText? messageInactiveText: 'Скрыть' : messageActiveText?messageActiveText: 'Показать'}}</button>
        </div>

        <div class="message" :class="{ 'show': showMessage }">
            {{message}}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: String,
        messageActiveText: String,
        messageInactiveText: String
    },
    data() {
        return {
            showMessage: false
        };
    }
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        background: transparent;
        border: none;
        color: white;
        cursor: pointer;
        transition: 0.1s;

        &:enabled:hover {
            transform: translateY(1px);
        }
    }
}

.alert {
    padding: 10px 14px;
    background: #ed2224;
    color: white;
    border-radius: 12px 4px 12px 4px;
    font-weight: 700;
}

.blue {
    background: #2a5cdb;
}

.gray {
    background: #3f4146;
}

.message {
    font-weight: 500;
    font-style: italic;
    opacity: 0;
    height: 0;
    overflow: hidden; /* скрывает содержимое, когда высота равна 0 */
    transition: opacity 0.5s ease, height 0s ease 0.5s; /* анимация появления только для opacity */
    word-wrap: break-all;
    word-break: break-word;
}

.message.show {
    padding: 10px 5px;
    height: auto; /* возвращаем обычную высоту */
    opacity: 1;
    transition: opacity 0.5s ease, height 0.5s ease;
}
</style>
