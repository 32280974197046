<template>
    <section class="welcome">
        <div class="welcome__content">
            <div class="login">
                <div class="login-inner-wrapper">
                    <img src="@/assets/Logo.svg" alt="" class="logo">
                    <h1 class="header">Пожалуйста <br> войдите в систему.</h1>
                    <div class="error-wrapper">
                    <Transition>
                        <div v-show="error" class="error">{{error}}</div>
                    </Transition>
                    </div>
                    <form @submit.prevent="loginRequest" class="form">
                        <label class="label">
                            <div class="title">Введите ваш email</div>
                            <Input v-model="email" @input="error = ''" :placeholder="'user@example.com'"/>
                        </label>
                        <label class="label">
                            <div class="title">Введите ваш пароль</div>
                            <Input v-model="password" @input="error = ''" :placeholder="'пароль'" :type="'password'"/>
                        </label>
                        <Button :loading="isLoginLoading" type="submit">Войти</Button>
                    </form>
                    <span class="version">{{getAppVersion}}</span>
                </div>
            </div>
        </div>
        <div id="background"></div>
    </section>
</template>
<script>

import { mapActions } from 'vuex'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'

export default{
    components:{
        Input,
        Button,
    },
    data(){
        return {
            email:'',
            password:'',
            error: '',
            errorTimeout: null,
            isLoginLoading: false
        }
    },
    computed:{
        getAppVersion () {
            return process.env.VUE_APP_VERSION;
        },
    },
    mounted(){
        if(localStorage.getItem('access_token'))
            this.$router.push({name: 'Main'})
    },
    methods:{
        ...mapActions(['login']),

        loginRequest(){
            /* eslint-disable */
            const emailRegexp = new RegExp(/^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i)

            if(emailRegexp.test(this.email)){
                this.isLoginLoading = true

                this.login({
                    username: this.email, 
                    password: this.password
                }).then(()=>{
                    this.$router.push({name:'Main'})
                }).catch((e)=>{
                    if(e.response && e.response.status == 401){
                        this.error = 'Неверный логин или пароль.'
                    }
                    else if(e.code == 'ERR_NETWORK'){
                        this.error = 'Сетевая ошибка, проверьте подключение.'
                    }
                    else{
                        this.error = 'Неизвестная ошибка.'
                    }
                }).finally(()=>{
                    this.isLoginLoading = false
                })
            }else
                this.error = 'Проверьте формат email!'
        }
    },
}
</script>

<style lang="scss" scoped>

@import '@/assets/_shared.scss';

.welcome{
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow: hidden;
    max-width: 100vw;
}

.welcome__content{
    height: 100vh;
    width: 100%;
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: flex-end;

    .login{
        height: 100vh;
        width: min-content;
        margin-right: 50px;
        background: rgba(black, .5);
        display: flex;
        padding: 60px 120px;
        justify-content: center;
        backdrop-filter: blur(3px);

        .error-wrapper{
            height: 38px;
        }

        .login-inner-wrapper{
            width: 400px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-between;

            .form{
                display: flex;
                flex-direction: column;
                width: 100%;

                .input{
                    padding: 13px 17px;
                }

                button{
                    width: min-content;
                    padding: 17;
                    transition: .5s;

                    .loading-icon{
                        width: 30px;
                        height: 30px;
                        stroke-width: 8px;
                    }
                }

                .label{
                        display: block;
                        margin-bottom: 30px;

                        .title{
                            color: white;
                            margin-bottom: 10px;
                        }
                }
            }

            .logo{
                height: 34px;
                margin-bottom: 100px;
            }

            .header{
                color: white;
                font-size: 46px;
                font-weight: 600;
            }

            .version{
                color: white;
                font-size: 18px;
                font-weight: 200;
            }
        }
    }
}

.error{
                min-width: 400px;
                border-radius: 3px;
                padding: 10px;
                height: 38px;
                background: $red;
                color: $white;
            }

.welcome__container{
    display: flex;
    justify-content: center;
    max-width: 1304px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}


.welcome__login-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.welcome__login-header-item{
    display: flex;
    flex-direction: column;
}

.welcome__login-header-text{
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
}

.welcome__content-inner-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

#background{
    position: absolute;
    top: 0;
    left: 0;
    width: 200vw;
    height: 100vh;
    background: url('~@/assets/WelcomeBackground.png');
    background-size: 100vw auto ;
    animation: background-move 120s infinite linear;
    background-color: black;
}

@keyframes background-move {
    0%{
        transform: translateX(0px);
    }

    0%{
        transform: translateX(-100vw);
    }
}

//VUE-TRANSITIONS
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
