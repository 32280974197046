<template>
  <div class="pagination">
      <div class="pagination__spacer" />
      <div class="pagination__controls">
        <button
          class="pagination__arrow"
          @click="setPage(options.page - 1)"
          :disabled="options.page <= 1"
        >
          <svg-icon type="mdi" :path="icons.arrowLeft"></svg-icon>
        </button>
        <div class="pagination__numbers">
          <button
            v-for="number in pages"
            :key="number"
            @click="setPage(number)"
            :class="['pagination__number', (options?.page === number && 'pagination__number_selected')]"
            :disabled="number == options?.page"
          >
            {{ number }}
          </button>
        </div>
        <button
          class="pagination__arrow"
          @click="setPage(options.page + 1)"
          :disabled="options.page >= meta.pageCount"
        >
          <svg-icon type="mdi" :path="icons.arrowRight"></svg-icon>
        </button>
      </div>
      <div class="pagination__take">
        <select
          :value="options?.take"
          @change="(event) => setTake(parseInt(event.target.value))"
        >
          <option
            v-for="option in takeOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

export default {
  props: {
    options: Object,
    meta: Object,
  },
  components: {
    SvgIcon,
  },
  data () {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        arrowRight: mdiChevronRight,
      },
      takeOptions: [
        5,
        10,
        20,
        30,
        40,
        50,
      ],
      showPages: 10,
    };
  },
  methods: {
    updateOptions (newOptions) {
      this.$emit('options-change', newOptions);
    },
    setPage (page) {
      this.updateOptions({ ...this.options, page });
    },
    setTake (take) {
      console.log(take)
      this.updateOptions({ ...this.options, take });
    },
  },
  computed: {
    pageNumbers () {
      const numbers = Array.from({ length: this.meta?.pageCount }, (_, i) => i + 1);
      return numbers;
    },
    pages () {
      const currentPage = this.options?.page;
      const totalPages = this.meta?.pageCount;
      const sidePages = 2;
      let pages = [];

      if (totalPages === 1) {
        return [1];
      }

      for (let i = 1; i <= Math.min(2, totalPages); i++) {
        pages.push(i);
      }
      if (currentPage - sidePages > 3) {
        pages.push('...');
      }

      // Добавление страниц вокруг текущей страницы
      const startPage = Math.max(3, currentPage - sidePages);
      const endPage = Math.min(totalPages - 2, currentPage + sidePages);
      for (let i = startPage; i <= endPage; i++) {
        if (i !== 1 && i !== totalPages) {
          pages.push(i);
        }
      }

      // Добавление многоточия и последних страниц
      if (currentPage + sidePages < totalPages - 2) {
        pages.push('...');
      }
      for (let i = Math.max(totalPages - 1, endPage + 1); i <= totalPages; i++) {
        if (!pages.includes(i)) {
          pages.push(i);
        }
      }

      return pages;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-radius: 9px;
  padding: 0 16px;
  background: #3f4146;
  border-radius: 9px;

  & > * {
    flex: 1;
  }

  // &__spacer {}

  &__controls {
    display: flex;
    align-items: center;
    gap: 48px;
  }

  &__arrow {
    border: none;
    padding: 0;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 8px;
    color: #73757c;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  &__numbers {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__number {
    cursor: pointer;
    padding: 0 8px;
    background: none;
    border: none;
    font-size: 18px;
    line-height: 1;
    text-align: center;
    color: #73757c;

    &_selected {
      color: $light-gray;
    }
  }

  &__take {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    select {
      cursor: pointer;
      background: none;
      border: none;
      color: white;
      font-size: 18px;
      line-height: 1;
      text-align: center;
    }

    option {
      color: black;
    }

    &:focus {
      outline: none;
    }
  }
}
</style>
