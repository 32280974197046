<template>
  <div class="artist-update-modal">
    <h3 class="title">Редактировать артиста</h3>
    <Alert v-show="error" class="alert">{{error}}</Alert>
    <Alert blue v-show="success">Артист успешно обновлён</Alert>
    <p class="sub-title">Имя</p>
    <Input v-model.trim="artistData.name" :placeholder="'имя артиста'" class="form-item" :disabled="loading" />
    <p class="sub-title">Описание</p>
    <Input v-model.trim="artistData.description" :placeholder="'описание'" class="form-item" :disabled="loading"/>
    <p class="sub-title">Spotify id</p>
    <Input v-model.trim="artistData.spotify_id" :placeholder="'spotify id'" class="form-item" :disabled="loading"/>
    <p class="sub-title">Apple music id</p>
    <Input v-model.trim="artistData.apple_id" :placeholder="'apple music id'" class="form-item" :disabled="loading"/>
    <div class="actions">
      <Button :disabled="!canUpdateArtist || loading" red @click="updateArtistRequest" :loading="loading">Обновить</Button>
      <Button gray :disabled="loading" @click="$emit('close')">Отмена</Button>
    </div>
  </div>
</template>

<script>
import Input from './Input.vue'
import Button from '@/components/Button.vue'
import Alert from './Alert.vue'
import { mapActions } from 'vuex'

export default {
  components:{
    Input,
    Button,
    Alert
  },
  props:{
    artist: Object
  },
  mounted(){
    this.artistData = {...this.artist}
  },
  computed:{
    canUpdateArtist(){
      return JSON.stringify(this.artist) != JSON.stringify(this.artistData)
    }
  },
  data(){
    return{
      artistData:{},
      valid: false,
      loading: false,
      error: '',
      success: false,
    }
  },
  methods:{
    ...mapActions(['updateArtist']),

    updateArtistRequest(){
      this.validate()

      if(this.valid){
        this.loading = true
        this.updateArtist(this.artistData).finally(() => {
          this.loading = false
        }).then(() => {
          this.success = true

          setTimeout(() => {
            this.success = false
          },3000)
        })
      }
    },

    validate() {
      this.valid = false;

      if (!this.artistData) {
        this.error = 'Нет данных';
        return false;
      }

      if (!this.artistData.name || this.artistData.name.trim().length <= 0) {
        this.error = 'Неверно имя артиста';
        return false;
      }

      if (this.artistData.description && (this.artistData.description.trim().length < 12 || this.artistData.description.trim().length > 255)) {
        this.error = 'Описание должно быть длиной от 12 до 255 символов';
        return false;
      }

      const spotifyRegex = /^[a-zA-Z0-9]{22}$/;
      if (this.artistData.spotify_id &&  !this.artistData.spotify_id.match(spotifyRegex)) {
        this.error = 'Невалидное значение spotify ID';
        return false;
      }

      const appleRegex = /^[0-9]{5,10}$/;
      if (this.artistData.apple_id && !this.artistData.apple_id.match(appleRegex)) {
        this.error = 'Невалидное значение apple ID';
        return false;
      }

      this.valid = true;
      return true;
    }
  },
  watch:{
    artistData:{
      deep: true,
      handler(){
        this.error = ''
        this.valid = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";


.artist-update-modal{
  margin-top: 150px;
  margin-bottom: 150px;
  padding: 40px;
  width: 480px;
  height: min-content;
  border-radius: 9px;
  background: rgba(#1f2023, 0.5);
  backdrop-filter: blur(12px);
  color: white;

  .alert{
    margin-bottom: 10px;
  }

  .title{
    margin-bottom: 20px;
  }

  .sub-title{
    margin-bottom: 5px;
  }

  .form-item{
    margin-bottom: 20px;
  }

  .actions{
    display: flex;

    button{
      margin-right: 10px;
    }
  }
}
</style>