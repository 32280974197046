<template>
    <div class="avatar">
        <img :src="src">
    </div>
</template>
<style lang="scss" scoped>
    .avatar{
        width: 100%;
        height: 100%;
        -webkit-mask-image: url('~@/assets/icons/img-form-mask.svg');
        mask-image: url('~@/assets/icons/img-form-mask.svg');
        mask-repeat: no-repeat;

        img{
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }
</style>
<script>
export default {
    props:{
        src: String
    }
}
</script>