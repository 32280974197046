<template>
  <Modal v-bind="$props" @close="closeCreateSurveyModal()">
    <div class="survey-modal">
      <h3 class="title">Создание опроса:</h3>
        <Alert 
          style="margin-bottom: 10px;"
          v-show="error"
        >{{error}}</Alert>
      <form class="survey" @submit.prevent="createSurveyRequest">
        <p>Название опроса*</p>
        <Input
          placeholder="Какой-то опрос..."
          type="text"
          v-model="survey.name"
        />
        <p>SLUG</p>
        <Input
          placeholder="AAAA"
          type="text"
          v-model="survey.slug"
        />
        <p>Активен до:</p>
        <Input
          type="date"
          v-model="expiredAtString"
          :min="minStartAt"
        />

        <div class="actions">
          <Button class="button" small red type="submit" :disabled="loading" :loading="loading">
            Создать опрос <SvgIcon type="mdi" :path="icons.plus" v-show="!loading"/>
          </Button>
          <Button class="button" @click="closeCreateSurveyModal()" type="button" small gray v-show="!loading">
            Отмена
          </Button>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import Alert from "@/components/Alert.vue";
// import Select from "@/components/Select.vue";
import Button from '@/components/Button.vue'
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlus } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Modal,
    Input,
    // Select,
    Button,
    Alert,
    SvgIcon
  },
  props: {
    ...Modal.props,
  },
  data() {
    return {
      survey: {
        slug: undefined,
        name: '',
        expiredAt: undefined,
      },
      icons:{
        plus: mdiPlus
      },
      error:'',
      expiredAtString: null,
      loading: false
    };
  },
  mounted() {
    this.fetchConstants()
  },
  methods: {
    ...mapActions([
      'fetchConstants',
      'createSurvey'
    ]),

    validate(){
      if(this.survey.slug){
        if(!this.survey.slug.match(this.constants.SurveySlugRegExp)){
          this.error = 'SLUG должен содержать только заглавные латинские буквы и цифры'
        }
      }

      if(!this.survey.name.match(/^[a-zA-Zа-яА-Я0-9\s.,!?]{3,255}$/)){
        this.error = `Название должно соблюдать эти правила: -латиница\n-кирилица\n-цифры\n-точка, запятая, ! и ?\n-длина от 3 до 255 символов`
      }

      if(this.survey.expiredAt){
        const currentDate = new Date()
        console.log(this.survey.expiredAt, currentDate > this.survey.expiredAt)
        if(currentDate > this.survey.expiredAt){
          this.error = 'Дата конца опроса должна быть из будущего.'
        }
      }
    }, 

    createSurveyRequest(){
      this.validate()

      if(!this.error){
        this.loading = true
        this.createSurvey(this.survey)
        .catch((e)=>{
          console.log(e)
          this.error='Произошла неизвестная ошибка!'
        })
        .then(({data})=>{
          this.$emit("create",data);
        })
        .finally(() => {
          this.loading = false
        })
      }
    },

    closeCreateSurveyModal(){
      this.survey.name = ''
      this.survey.slug = undefined
      this.survey.expiredAt = undefined
      this.$emit("close");
    },

    minStartAt() {
      const currentDate = new Date();
      return currentDate.toISOString().substr(0, 10);
    },
  },
  computed: {
    ...mapGetters(['constants'])
  },
  watch:{
    survey:{
      deep:true,
      handler(){
        console.log('w')
        this.error = ''
      }
    },
    expiredAtString(){
      this.survey.expiredAt = new Date(this.expiredAtString) 
    }
  }
};
</script>

<style lang="scss" scoped>
.survey-modal{
  border-radius: 9px;
  padding: 40px;
  width: 100%;
  height: 100vh;
  width: 480px;
  height: min-content;
  background: rgba(#1f2023, 0.5);
  backdrop-filter: blur(12px);
  color: white;

  .title{
    margin-bottom: 20px;
  }

  .survey{
    padding: 20px 0px;

    input{
      margin-bottom: 20px;

      &:last-child{
        margin: 0;
      }
    }
  }

  .actions{
    display: flex;

    button{
      margin-right: 10px;

      &:last-child{
        margin: 0;
      }
    }
  }

  .button{
    svg{
      margin-left: 10px;
    }
  }
}
</style>