<template>
    <h2 class="header">
        <slot/>
    </h2>
</template>
<style lang="scss" scoped>
    .header{
        margin: 0;
        padding: 0;
        color: white;
    }
</style>