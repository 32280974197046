const UserRoles = {
  all: {
    key: undefined,
    name: 'All',
    title: 'Все',
  },
  banned: {
    key: 'banned',
    name: 'Banned',
    title: 'Заблокирован',
  },
  user: {
    key: 'user',
    name: 'User',
    title: 'Пользователь',
  },
  admin: {
    key: 'admin',
    name: 'Admin',
    title: 'Админ',
  },
  superuser: {
    key: 'superuser',
    name: 'Superuser',
    title: 'Суперюзер',
  },
};

export default UserRoles;
