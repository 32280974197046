import PurchaseType from "./PurchaseType";

export const PurchaseStatuses = {
  PENDING: 'В ожидании',
  PAID: 'Оплачено',
  REJECTED: 'Отклонено',
  REFUNDED: 'Возврат',
  USED: 'Использовано',
};

const Subscriptions = {
  CLASSIC: {
    type: PurchaseType.SUBSCRIPTION,
    name: 'CLASSIC',
    displayName: 'Classic',
    description: 'Base subscription',
    amount: 10,
  },
  PREMIUM: {
    type: PurchaseType.SUBSCRIPTION,
    name: 'PREMIUM',
    displayName: 'Premium',
    description: 'Better subscription',
    amount: 15,
  },
};

const Products = {
  MYLABEL_CUSTOM_PC_LINE: {
    type: PurchaseType.PRODUCT,
    name: 'MYLABEL_CUSTOM_PC_LINE',
    displayName: 'Custom Publisher line',
    description: 'You can set your own PC',
    amount: 10,
  },
  MYLABEL_EARLY_DATE: {
    type: PurchaseType.PRODUCT,
    name: 'MYLABEL_EARLY_DATE',
    displayName: 'Release earlier',
    description: 'You can release earlier',
    amount: 10,
  },
};

export const Purchases = {
  ...Subscriptions,
  ...Products
};

export { Subscriptions, Products };
