<template>
  <Chip class="chip" small :color="releaseStatus.color" v-if="releaseStatus">
    {{ releaseStatus.title }}
  </Chip>
</template>

<script>
import ReleaseStatus from '../utils/ReleaseStatus';
import Chip from './Chip.vue';

export default {
  components: {
    Chip,
  },
  props: {
    status: String,
  },
  data () {
    return {
      ReleaseStatus,
    };
  },
  computed: {
    releaseStatus () {
      return ReleaseStatus[this.status];
    },
  },
};
</script>

<style>
.chip {
  font-size: 1rem;
  white-space: nowrap;
}
</style>
