// import axios from 'axios';

const audioPlayer = {
    state: () => ({
        queue: [],
        currentTrackIndex: 0,
        isPlaying: false,
        coverUuid: null,
    }),
    actions: {
        
    },
    mutations: {
        setIsPlaying (state, value) {
            state.isPlaying = value;
        },
        setQueue (state, tracks) {
            state.queue = tracks;
            state.currentTrackIndex = 0;
        },
        clearQueue (state) {
            state.queue = [];
            state.currentTrackIndex = 0;
        },
        setCoverUuid (state, fileUuid) {
            state.coverUuid = fileUuid;
        },
        setCurrentTrackIndex (state, index) {
            const maxIndex = state.queue.length - 1;
            if (index > maxIndex)
                state.currentTrackIndex = maxIndex;
            else if (index < 0)
                state.currentTrackIndex = 0;
            else
                state.currentTrackIndex = index;
        },
        nextTrack (state) {
            const maxIndex = state.queue.length - 1;
            if (++state.currentTrackIndex > maxIndex)
                state.currentTrackIndex = maxIndex;
        },
        prevTrack (state) {
            if (--state.currentTrackIndex < 0)
                state.currentTrackIndex = 0;
        }
    },
    getters: {
        queue: (state) => state.queue,
        currentTrack: (state) => state.queue[(state.currentTrackIndex)],
        currentTrackIndex: (state) => state.currentTrackIndex,
        coverUuid: (state) => state.coverUuid,
        isPlaying: (state) => state.isPlaying,
    },
};

export default audioPlayer;
