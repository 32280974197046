function getUserNameFromProfile(profile) {
  const { first_name, last_name } = profile;
  
  if (first_name || last_name) {
    return [first_name, last_name].join(' ');
  }
  return 'Без имени';
}

export default function getUserName(userOrProfile) {
  const profile = userOrProfile.profile || userOrProfile;

  return getUserNameFromProfile(profile);
}
