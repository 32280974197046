<template>
    <div class="wrapper" @click="clickHandler">
        <input
            v-bind="$attrs"
            :disabled="readOnly || disabled"
            :placeholder="placeholder"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            :class="['input', styleSearch ? 'input--search' : 'input--default', {'input--error':error}, {'input--copy':readOnly} ]"
        >
        <button class="copy" v-if="readOnly">
            <SvgIcon :path="icons.copy" type="mdi" :size="18" />
        </button>
    </div>
</template>
<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.wrapper{
    position: relative;
}

.copy{
    width: 40px;
    height: 100%;
    background: transparent;
    right: 0;
    top: 0;
    color: white;
    border: none;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:active{
        transform: translateY(2px);
    }
}

.input{
    box-sizing: border-box;
    width: 100%;
    padding: 10px 13px;

    border: 1px solid $dark-gray;
    border-radius: 11px;
    background: #1F2023;
    color: white;

    &::placeholder{
        color:$dark-gray;
    }

    &:disabled{
        cursor: not-allowed;
        opacity: .5;
        border: 1px solid $gray;
    }

    &--default{
        border: 1px solid $dark-gray;
        border-radius: 11px;
    }

    &--error{
        color:$red;
        border: 1px solid $red;
        &::placeholder{
            color: rgba($color: $red, $alpha: .5);
        }
    }

    &--search{
        border: none;
        border-bottom: 2px solid $gray;
    }

    &--copy{
        opacity: 1 !important;
        color: white !important;
        border-color: white !important;
    }

    &:focus{
        border-color: $dark-gray;
        outline: none;
    }

    &[type="date"]::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
}
</style>

<script>
import getDashFormattedDate from '@/utils/getDashFormattedDate';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiContentCopy } from '@mdi/js';

export default {
    components:{
        SvgIcon
    },
    data(){
        return{
            icons:{
                copy: mdiContentCopy
            }
        }
    },
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        error: Boolean,
        styleSearch: Boolean,
        readOnly: Boolean,
        placeholder: String,
        disabled: Boolean
    },
    methods: {
        getDashFormattedDate,

        clickHandler(){
            if(this.readOnly){
                navigator.clipboard.writeText(this.modelValue)
            }
        }
    },
    watch: {
        modelValue() {
            if (this.$attrs.type === 'date') {
                const value = this.getDashFormattedDate(this.modelValue);
                this.$emit('update:modelValue', value);
            }
        },
    },
};
</script>
