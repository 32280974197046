<template>
  <Modal v-bind="$props" @close="close()">
    <div class="survey-modal">
      <h3 class="title">Удалить опрос "{{survey.name}}" ?</h3>
        <Alert 
          style="margin-bottom: 10px;"
          v-show="error"
        >{{error}}</Alert>
        <div class="actions">
          <Button @click="deleteSurveyRequest()" class="button" small red type="submit" :disabled="loading" :loading="loading">
            Удалить <SvgIcon type="mdi" :path="icons" v-show="!loading"/>
          </Button>
          <Button class="button" @click="close()" type="button" small gray v-show="!loading">
            Отмена
          </Button>
        </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Alert from "@/components/Alert.vue";
import Button from '@/components/Button.vue'
import { mapActions } from 'vuex';

export default {
  components: {
    Modal,
    Button,
    Alert,
  },
  props: {
    ...Modal.props,
    survey: Object
  },
  data() {
    return {
      error:'',
      loading: false
    };
  },
  methods: {
    ...mapActions([
      'deleteSurvey'
    ]),

    deleteSurveyRequest(){
      this.loading = true
      this.deleteSurvey(this.survey.uuid).then(() => {
        this.$emit('delete', this.survey)
      }).finally(() => {
        this.loading = false
      }).catch(error => {
        console.error(error)
        this.error = 'Произошла ошибка'
      })
    },

    close(){
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-modal{
  border-radius: 9px;
  padding: 40px;
  width: 100%;
  height: 100vh;
  width: 480px;
  height: min-content;
  background: rgba(#1f2023, 0.5);
  backdrop-filter: blur(12px);
  color: white;

  .title{
    margin-bottom: 20px;
  }

  .survey{
    padding: 20px 0px;

    input{
      margin-bottom: 20px;

      &:last-child{
        margin: 0;
      }
    }
  }

  .actions{
    display: flex;

    button{
      margin-right: 10px;

      &:last-child{
        margin: 0;
      }
    }
  }

  .button{
    svg{
      margin-left: 10px;
    }
  }
}
</style>