import axios from 'axios';

const users = {
  state: () => ({
    users: [],
    metadata: {},
    activeUsers: [],
  }),
  actions: {
    fetchUsers({commit}, pagination, replace = false){
      const params = {
        page: pagination?.page ?? 1,
        take: pagination?.take ?? 50,
        role: pagination?.role ?? undefined,
        verified: pagination?.verified ?? undefined,
        order: pagination?.order ?? undefined,
      }
      return new Promise( (resolve, reject) => {
        axios.get('/user-profiles-data', { params }).then(response => {
          if (replace) {
            commit('setUsers', response.data.data)
          } else {
            commit('updateUsers', response.data.data)
          }
          resolve(response)
        }).catch(e => reject(e))
      })
    },
    fetchUser ({commit, dispatch}, userUuid) {
      const credential = dispatch('fetchCredentialByUuid', userUuid);
      const profile = dispatch('fetchProfileByCredentials', userUuid);
      return Promise.all([credential, profile]).then(([credential, profile]) => {
        const user = {
          ...credential,
          profile
        };
        commit('setUser', user);
        return user;
      });
    },
    fetchUsersMetadata({commit}){
      return new Promise( (resolve, reject) => {
        axios.get('/meta-data').then(response => {
          commit('setUsersMetadata', response.data)
          resolve(response.data);
        }).catch(e => reject(e))
      })
    },
    fetchCredentialByUuid(_, credential_uuid){
      return new Promise( (resolve, reject) => {
        axios.get(`/credential/${credential_uuid}`).then(response => {
          resolve(response.data);
        }).catch(e => reject(e))
      })
    },
    fetchProfileByCredentials(_, credentials_uuid){
      return new Promise( (resolve, reject) => {
        axios.get(`/profile/by_credentials/${credentials_uuid}`).then(response => {
          resolve(response.data);
        }).catch(e => reject(e))
      });
    },
    fetchActiveUsers({ dispatch, commit}, refetchMetadata = false){
      return new Promise((resolve, reject) => {
        const metadataPromise = new Promise((resolve) => {
          if (refetchMetadata) {
            resolve(
              dispatch('fetchUsersMetadata')
            );
          } else {
            resolve(this.getters.getUsersMetadata);
          }
        });

        metadataPromise.then(metadata => {
          const activeUsers = metadata?.sessions ?? [];
          const usersPromises = activeUsers.map(user => {
            return Promise.all([
              dispatch('fetchCredentialByUuid', user.credential?.uuid),
              dispatch('fetchProfileByCredentials', user.credential?.uuid)
            ]).then(([credential, profile]) => {
              const userWithProfile = {
                ...credential,
                profile
              };
              commit('setUser', userWithProfile);
              return userWithProfile;
            });
          });

          Promise.all(usersPromises).then(users => {
            commit('setActiveUsers', users);
            resolve(users);
          }).catch(e => reject(e));
        });
      })
    },
    banUser({commit}, { credentials_uuid, ban_reason }) {
      const payload = {
        ban_reason
      };
      return new Promise( (resolve, reject) => {
        axios.post(`/ban/${credentials_uuid}`, payload).then(response => {
          resolve(response.data);
          commit('setUser', response.data);
        }).catch(e => reject(e))
      })
    },
    unbanUser({commit}, credentials_uuid) {
      return new Promise( (resolve, reject) => {
        axios.post(`/unban/${credentials_uuid}`).then(response => {
          resolve(response.data);
          commit('setUser', response.data);
        }).catch(e => reject(e))
      })
    },
  },
  mutations: {
    setUsers(state, users){
      console.log('setUsers')
      state.users = users.map(user => ({...user, avatar_is_loading: true}));
    },
    updateUsers(state, users){
      users.forEach(user => {
        const index = state.users.findIndex(u => u.uuid == user.uuid);
        if (index != -1) {
          const oldUser = state.users[index];
          state.users[index] = {
            ...oldUser,
            ...user
          }
        } else {
          state.users.push(user);
        }
      });
    },
    setUsersMetadata(state, metadata){
      state.metadata = metadata;
    },
    setActiveUsers(state, users){
      state.activeUsers = users;
    },
    setUser(state, user){
      const index = state.users.findIndex(u => u.uuid == user.uuid);
      if (index != -1) {
        const oldUser = state.users[index];
        state.users[index] = {
          ...oldUser,
          ...user
        }
      } else {
        state.users.push(user);
      }
    },
  },
  getters: {
    users: state => state.users,
    getUser: state => uuid => state.users.find(user => user.uuid == uuid),
    getUsersMetadata: state => state.metadata,
    activeUsers: state => state.activeUsers,
  },
};

export default users;
