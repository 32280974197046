<template>
  <textarea
    :value="modelValue"
    @input="updateModelValue"
    :class="['textarea', styleSearch ? 'textarea--search' : 'textarea--default', {'textarea--error': error}]"
    ref="textarea"
    :style="{ maxHeight: maxHeight ? maxHeight + 'px' : 'none' }"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    error: Boolean,
    styleSearch: Boolean,
    maxHeight: {
      type: [Number, String],
      default: null,
    },
  },
  methods: {
    updateModelValue(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
  watch: {
    modelValue() {
      this.$refs.textarea.style.height = 'auto';
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 2 + 'px';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 13px;
  border: 1px solid $dark-gray;
  border-radius: 11px;
  background: #1F2023;
  color: white;
  resize: vertical;

  &::placeholder {
    color: $dark-gray;
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px solid $gray;
  }

  &--default {
    border: 1px solid $dark-gray;
    border-radius: 11px;
  }

  &--search {
    border: none;
    border-bottom: 2px solid $gray;
  }

  &--error {
    color: $red;
    border: 1px solid $red;

    &::placeholder {
      color: rgba($color: $red, $alpha: .5);
    }
  }

  &:focus {
    border-color: $dark-gray;
    outline: none;
  }

  &[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}
</style>
