<template>
  <div class="select">
    <button
      v-for="item in items"
      :key="item.key"
      @click="selectItem(item)"
      :class="[{ active: modelValue == item.value }]"
    >
      {{ item.name }}
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      items: Array,
      modelValue: String,
    },
    methods: {
      selectItem (item) {
        this.$emit('update:modelValue', item.value);
        this.$emit('select', item);
      }
    }
  }
</script>

<style lang="scss" scoped>
.select {
  display: flex;

  button{
    font-size: 15px;
    padding: 9px;
    flex-grow: 1;
    border: none;
    background: #3f4146;
    color: white;
    cursor: pointer;

    &:first-child{
      border-radius: 4px 0px 0px 4px;
    }

    &:last-child{
      border-radius: 0px 4px 4px 0px;
    }

    &:hover{
      background: #61646a;
    }
  }

  .active{
    transition: .6s;
    background: #ED2242;
  }
}
</style>