<template>
  <div class="survey-answers">
    <div class="title">
      <p>Ответы пользователей на опрос:</p>
      <Header>{{surveyByUuid($route.params.survey_uuid)?.name}}</Header>
      <IconButton @click="$router.push({name:'Survey'})" :tooltip="'Вернуться к опросам'" :icon="icons.back" class="back-btn" />
    </div>
    <div class="loading-logo" v-if="isLoading">
      <LoadingLogo/>
    </div>
    <p v-else-if="!isLoading && surveyUsers.length">Ответов пока нет</p>
    <div class="list" v-else>
      <Table>
        <table>
          <thead>
            <th>email</th>
            <th>прогресс</th>
            <th>дата последнего ответа</th>
            <th>дата</th>
            <th>действия</th>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </Table>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { mdiArrowLeftBold } from '@mdi/js'
import Header from '@/components/Header.vue'
import Table from '@/components/Table.vue'
import IconButton from '@/components/IconButton.vue'
import LoadingLogo from '@/components/LoadingLogo.vue'


export default {
  components:{
    Header,
    Table,
    IconButton,
    LoadingLogo
  },
  data(){
    return{
      surveyData:null,
      isLoading: true,
      icons:{
        back: mdiArrowLeftBold
      }
    }
  },
  mounted(){
    console.log(this.$route.params.survey_uuid)
    this.fetchSurvey(this.$route.params.survey_uuid)
    .then(response => {
      this.fetchSurveyUsers(response.data)
      .then(response => {
        console.log(response)
      })
      .finally(()=>{
        this.isLoading = false
      })
    })
  },
  methods:{
    ...mapActions(['fetchSurvey','fetchSurveyUsers'])
  },
  computed:{
    ...mapGetters(['surveyByUuid'])
  }
}
</script>
<style scoped lang="scss">
.survey-answers{
  color: white;
  padding-top: 90px;
  max-width: 900px;
  margin: 0 auto;

  .title{
    padding: 30px 0px;
    position: relative;

    .back-btn{
      position: absolute;
      left: -35px;
      top: calc((100%/2) - 20px);
    }
  }

  .loading-logo{
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

</style>