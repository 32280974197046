<template>
  <div class="artist-info-modal">
    <h3 class="title">Артист</h3>
    <Alert v-show="error" class="alert">{{error}}</Alert>
    <p class="sub-title">Имя</p>
    <Input v-model="artistData.name" :readOnly="true" :placeholder="'имя артиста'" class="form-item"  />
    <p class="sub-title">Описание</p>
    <Input v-model="artistData.description" :readOnly="true" :placeholder="'описание'" class="form-item"/>
    <p class="sub-title">Spotify id</p>
    <Input v-model="artistData.spotify_id" :readOnly="true" :placeholder="'spotify id'" class="form-item"/>
    <p class="sub-title">Apple music id</p>
    <Input v-model="artistData.apple_id" :readOnly="true" :placeholder="'apple music id'" class="form-item"/>
    <p class="sub-title">ID</p>
    <Input v-model="artistData.uuid" :readOnly="true" :placeholder="'apple music id'" class="form-item"/>
    <p class="sub-title">Дата создания</p>
    <Input v-model="artistData.created_at" :readOnly="true" :placeholder="'apple music id'" class="form-item"/>
    <p class="sub-title">Дата последнего обновления</p>
    <Input v-model="artistData.updated_at" :readOnly="true" :placeholder="'apple music id'" class="form-item"/>
    <div class="actions">
      <Button gray :disabled="loading" @click="$emit('close')">Закрыть</Button>
    </div>
  </div>
</template>

<script>
import Input from './Input.vue'
import Button from '@/components/Button.vue'
import Alert from './Alert.vue'

export default {
  components:{
    Input,
    Button,
    Alert
  },
  props:{
    artist: Object
  },
  mounted(){
    this.artistData = {...this.artist}
  },
  data(){
    return{
      error: '',
      success: false,
      artistData: {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";


.artist-info-modal{
  margin: 50px 0px;
  padding: 40px;
  width: 480px;
  height: min-content;
  border-radius: 9px;
  background: rgba(#1f2023, 0.5);
  backdrop-filter: blur(12px);
  color: white;

  .alert{
    margin-bottom: 10px;
  }

  .title{
    margin-bottom: 20px;
  }

  .sub-title{
    margin-bottom: 5px;
  }

  .form-item{
    margin-bottom: 20px;
  }

  .actions{
    display: flex;

    button{
      margin-right: 10px;
    }
  }
}
</style>