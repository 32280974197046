<template>
  <div class="user">
    <div class="user__info">
      <div
        :class="[
          'user__avatar',
          'avatar',
          user.isActive ? 'avatar--active' : '',
        ]"
      >
        <Transition name="resize">
          <img
            v-if="this.file(this.user.profile?.avatar_url)?.base64"
            :src="this.file(this.user.profile?.avatar_url)?.base64"
            alt="Avatar"
          />
        </Transition>
        <Transition name="resize">
          <img
            v-if="!this.file(this.user.profile?.avatar_url)?.base64"
            :src="require('@/assets/placeholder.png')"
            alt="Avatar Placeholder"
          />
        </Transition>
      </div>
      <span>{{ getUserName(user) }}</span>
      <span>{{ user.email }}</span>
      <span>{{ getFormattedDate(user.created_at) }}</span>
    </div>
    <div class="user__actions" @click.stop>
      <button @click="copyToClipboard(user.email)" target="_blank">
        <img src="@/assets/icons/copy.svg" alt="" />
      </button>
    </div>
  </div>
</template>

<script>
import copyToClipboard from "@/utils/copyToClipboard";
import getUserName from "@/utils/getUserName";
import getFormattedDate from "@/utils/getFormattedDate";
import { mapGetters } from "vuex";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    copyToClipboard,
    getUserName,
    getFormattedDate,
  },
  computed: {
    ...mapGetters(["file"]),

    avatarSrc() {
      return this.file(this.user.profile?.avatar_url)?.base64;
    },
  },
};
</script>

<style lang="scss" scoped>
.resize-enter-active,
.resize-leave-active {
  transition: all 0.3s;
}

.resize-enter-from,
.resize-leave-to {
  transform: scale(0.6);
  opacity: .7;
}

.user {
  cursor: pointer;
  border: none;
  width: 100%;
  background: #3f4146;
  border-radius: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &__actions {
    padding: 15px;

    a {
      display: block;
      height: 30px;
      width: 30px;
      padding: 5px;
      border-radius: 4px;
      border: none;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }

      &:hover {
        background: #61646a;
      }

      &:nth-last-child {
        margin: 0;
      }
    }

    button {
      height: 30px;
      width: 30px;
      padding: 5px;
      border-radius: 4px;
      border: none;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        background: #61646a;
      }

      &:nth-last-child {
        margin: 0;
      }
    }
  }

  &__info {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    span {
      width: 22%;
      max-width: 200px;
      max-width: 22%;
      color: white;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.avatar {
  cursor: pointer;
  width: 36px;
  height: 36px;
  overflow: hidden;
  margin-right: 20px;
  position: relative;

  img {
    border-radius: 9px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--active {
    &::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      background: #00ff29;
      border-radius: 50%;
      right: -3px;
      bottom: -3px;
      border: 3px solid #3f4146;
    }
  }
}
</style>
