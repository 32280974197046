<template>
    <div class="modal-wrapper" :style="[{zIndex:zIndex}]" @click="$emit('close')">
        <div class="modal-body" @click.stop>
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    props:{
        zIndex:Number
    }
}
</script>

<style lang="scss" scoped>
.modal-wrapper{
    height: 100vh;
    max-height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    display: flex;
    justify-content: center;


    background: rgba($color: #000000, $alpha: .5);

    backdrop-filter: blur(12px);
    cursor: pointer;

    overflow-y: scroll;

    & > * {
        cursor: initial;
    }
}
</style>