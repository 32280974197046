<template>
  <span style="color:white">
  </span>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
  @import url('@/assets/_global.scss');
  @import url('@/assets/_nullstyle.scss');
  @import url('@/assets/vue-animation.scss');

  body{
    background: #1f2023;
    position: relative;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #bbbbbb;
  }
</style>
