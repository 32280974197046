<template>
  <slot class="table-wrapper"/>
</template>

<script>
export default {
  props:{
    headers: Array,
    items: Array
  },
}
</script>

<style lang="scss" scoped>

:slotted(table){
  text-align:left;
  color: white;
  width: 100%;
  border-collapse: separate; /* разделяем границы ячеек */
  border-spacing: 0 10px; /* задаем отступ между строками и столбцами */

  tr{
    border: 1px solid white;
    border-radius: 30px;
    overflow: hidden;
    background: #3f4146;
    cursor: pointer;

    &:hover{
      background: #494b51;
    }
  }

  td, th{
    padding: 15px;
    color: white;
  }

  th:last-child{
    text-align: center;
  }

  tr td:first-child {
    border-top-left-radius: 10px; /* округление верхнего левого угла */
    border-bottom-left-radius: 10px; /* округление нижнего левого угла */
  }

  tr td:last-child {
    border-top-right-radius: 10px; /* округление верхнего правого угла */
    border-bottom-right-radius: 10px; /* округление нижнего правого угла */
  }

  td{
    border-spacing: 10px;
    vertical-align: middle;
  }

  .table-cover{
    cursor: pointer; 
    width: 36px;
    height: 36px;
    border-radius: 9px;
    overflow: hidden;
    margin-right: 20px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }

  .table-cover-loading{
    cursor:wait; 
    width: 36px;
    height: 36px;
    border-radius: 9px;
    overflow: hidden;
    margin-right: 20px;
    animation: loading 2s linear infinite alternate;
  }

  .table-center {
    display: block; /* Установка элемента как блочного, чтобы использовать margin:auto */
    margin: 0 auto; /* Центрирование элемента по горизонтали */
  }

  .table-actions{
    display: flex;
    justify-content: center;

    a{
        display: block;
        height: 30px;
        width: 30px;
        padding: 5px;
        border-radius: 4px;
        border:none;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: 10px;

        img{
            width: 100%;
            height: 100%;
            // object-fit: cover;
        }

        &:hover{
            background:#61646a;
        }

        &:nth-last-child{
            margin:0;
        }
    }

    button{
      height: 30px;
      width: 30px;
      padding: 5px;
      border-radius: 4px;
      border:none;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 10px;
      color: white;

      img{
          width: 100%;
          height: 100%;
          object-fit: cover;
      }

      &:enabled:hover{
          background:#61646a;
      }

      &:disabled{
        color: #949494;
        cursor: not-allowed;
      }

      &:nth-last-child{
          margin:0;
      }
    }
  }

  .info{
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;


    .cover{
        cursor: pointer; 
        width: 36px;
        height: 36px;
        border-radius: 9px;
        overflow: hidden;
        margin-right: 20px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    p {
        margin: 0;
        color: white;
    }

    span{
        width: 22%;
        max-width: 200px;
        max-width: 22%;
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__status {
        display: flex;
        justify-content: center;
    }

    &__date {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        color: white;
        width: 100px;
    }

    &__transfered {
        display: flex;
        gap: 2px;
        align-items: center;
        padding: 2px 4px;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid #949494;
        color: #949494;
        font-weight: 500;

        svg {
            width: 14px;
            height: 14px;
        }
    }
  }

  .table-sortable{
    position: relative;
    cursor: pointer;
    background: transparent;
    border: none;
    color: white;

    &::before{
      transform: rotate(-90deg);
      transition: .2s;
      content: '';
      background-image: url('@/assets/icons/sortable.svg');
      background-size: contain; /* Установите размер фона по содержимому */
      background-repeat: no-repeat; /* Запретить повторение фона */
      display: inline-block; /* Для создания блочного элемента */
      width: 16px; /* Установите ширину */
      height: 16px; /* Установите высоту */
      position: absolute;
      left: -20px;
      top: 3px;
    }
  }

  .table-sortable-asc{
    &::before{
      transform: rotate(180deg);
    }
  }

  .table-sortable-desc{
    &::before{
      transform: rotate(0deg);
    }
  }
}

@keyframes loading {
  0% {
    background-color: #949494; /* начальный серый цвет */
  }
  100% {
    background-color: #3f4146; /* конечный синий цвет */
  }
}
</style>
