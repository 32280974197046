<template>
  <div class="dropdown" v-show="!loading">
    <label for="input" class="dropdown__label" v-if="label">
      {{ inputValue }}
    </label>
    <input 
      class="dropdown__input"
      id="input"
      ref="input"
      type="text"
      autocomplete="off"
      v-bind="$attrs"
      v-model="inputValue"
      @input="textInputHandler"
      @focus="openDropDown"
      @blur="closeDropDown"
    >
    <img class="dropdown__icon" src="@/assets/icons/play.svg" alt="">

    <div class="dropdown__items" v-if="isDropdownOpened && items.length">
      <button :class="['dropdown__item', selectedItem.id === item.id ? 'dropdown__item--active' : '']" v-for="item in (selectedItem.id ? items : filteredItems)" :key="item.id" @mousedown="selectItem(item)">
        {{ item.name }}
      </button>
    </div>
  </div>
  <div v-show="loading" class="dropdown dropdown--loading">
    <!-- loading -->
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    label: String,
    modelValue: [String, Number],
    loading: Boolean,
  },
  data () {
    return {
      inputValue: '',
      bufferInputValue: '',
      selectedItem: {
        id: null,
        name: '',
      },
      isDropdownOpened: false,
    };
  },
  mounted () {
    this.configureInput();
    this.selectModelValue();
  },
  methods: {
    configureInput () {
      // add blur on clicking Enter
      this.$refs.input.addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
          this.isDropdownOpened = false;
          this.$refs.input.blur();
        }
      });
    },

    clearInput () {
      this.inputValue = '';
    },

    selectItem (item) {
      this.selectedItem = item;
      this.inputValue = this.selectedItem.name;
      this.$emit('select', this.selectedItem.id);
      this.$emit('update:modelValue', this.selectedItem.id);
    },

    textInputHandler (e) {
      const inputValue = e.target.value;
      this.$emit('input', inputValue);
    },

    selectModelValue () {
      const foundItem = this.items?.find(item => item.id === this.modelValue);

      if (foundItem) {
        this.selectItem(foundItem);
      }
    },

    openDropDown() {
      this.isDropdownOpened = true
      this.bufferInputValue = this.inputValue
      this.clearInput()
    },

    closeDropDown() {
      if (this.inputValue === '') {
        this.inputValue = this.bufferInputValue
      }
      this.isDropdownOpened = false
    }
  },
  computed: {
    filteredItems () {
      return this.items.filter(item => 
        item.name
          .toLowerCase()
          .includes(this.inputValue.toLowerCase())
      );
    },
  },
  watch: {
    modelValue () {
      this.selectModelValue();
    },
    items () {
      this.clearInput();
      this.selectModelValue();
    },
  },
};
</script>

<style lang="scss" scoped>

.dropdown {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  color: white;

  &--loading{
    border-radius: 9px;
    height: 40px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%);
    background-size: 800px 100%;
    animation: loading 1.5s infinite;
  }

  @keyframes loading {
    0% {
        background-position: -400px 0;
    }
    100% {
        background-position: 400px 0;
    }
  }

  &__label {
    margin-bottom: 5px;
    margin-left: 12px;

    font-size: 18px;
  }

  &__input {
    position: relative;

    height: 40px;
    padding-top: 2px;
    padding-left: 16px;

    transition: .2s;

    border: 1px solid #D1D1D1;
    border-radius: 11px;
    background: #1F2023;
    color: white;

    font-size: 16px;

    &::placeholder{
      color: #CACCCF;
    }

    &:focus {
      z-index: 3;

      transition: .3s;

      outline: none;
    }
  }

  &__icon {
    position: absolute;
    right: 20px;
    bottom: 14px;

    width: 12px;
    height: 12px;

    transform: rotate(90deg);

    &--focus {
      z-index: 4;
    }
  }

  &__items {
    position: absolute;
    z-index: 1;
    top: calc(100% - 11px);

    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    width: 100%;
    padding: 20px 10px 10px 10px;

    border: 1px solid #ececec;
    border-radius:  0 0 16px 16px;
    background: #1F2023;
    color: white;

    gap: 4px;

    max-height: 400px;
    overflow-y: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    text-align: start;
    color: white;

    // height: 32px;
    padding: 8px 10px;

    cursor: pointer;

    border: none;
    border-radius: 8px;
    background: none;

    &--active {
      color: white;
    }

    &:hover {
      background:#61646a;
      color: white;
    }
  }
}
</style>
