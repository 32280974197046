<template>
  <Modal v-bind="$props" @close="close">
    <div class="survey-modal">
      <h3 class="title">Редактировать опрос</h3>
      <Alert v-show="success" blue>
        {{success}}
      </Alert>
      <Alert v-show="error">
        {{error}}
      </Alert>
      <div class="survey">
        <p class="sub-title">Название опроса*</p>
        <Input
          placeholder="Название опроса*"
          type="text"
          v-model="surveyData.name"
        />
        <p class="sub-title">Дата начала опроса:</p>
        <Input
          type="date"
          v-model="activatedAtString"
          :min="minStartAt"
        />
        <p class="sub-title">Дата конца опроса:</p>
        <Input
          type="date"
          v-model="expiredAtString"
          :min="minStartAt"
        />
        <p class="sub-title">Период опроса:</p>
        <Select
          placeholder="Выберите период опроса пользователей..." 
          :items="timeRange"
          v-model="surveyData.timeRangeType"
        />
      </div>

      <p class="sub-title">Вопросы:</p>
      <div class="questions" v-if="surveyData">
        <Transition name="fade">
        <div class="delete-question-modal" v-if="selectedDeleleteQuestion">
          <div class="body">
            <div class="title"> Действительно удалить вопрос "{{selectedDeleleteQuestion.value}}"?</div>
            <div class="actions">
              <Button :loading="isQuestionDeleting" :disabled="isQuestionDeleting" red small @click="deleteQuestionRequest">{{isQuestionDeleting?'':'Удалить'}}</Button>
              <Button  v-show="!isQuestionDeleting" gray small @click="closeDeleteQuestion">Отмена</Button>
            </div>
          </div>
        </div>
        </Transition>
        <div v-if="surveyData.questions && surveyData.questions.length" class="list">
          <div 
            v-for="(question, index) in surveyData.questions.sort((a, b) => a.order - b.order)" 
            :key="question.uuid" 
            :class="['question',{'drag-top': question.isDragTop},{'drag-bottom': question.isDragBottom}]"
            draggable="true"
            @dragstart="onDragStart($event, index)"
            @dragover="onDragOver($event, index)"
            @drop="onDrop($event, index)"
          >
              <div class="question-title-wrapper">
                <input type="text" v-model="question.value" class="questio-edit-value" v-if="question.isEdit">
                <p class="question-title" v-else>{{question.value}}</p>
                <div class="actions" v-if="question" v-show="!question.isLoading">
                  <button v-show="question.isShowOption" @click="question.isShowOption = false">
                    <SvgIcon type="mdi" :size="16" :path="icons.closedEye"/>
                  </button>
                  <button v-show="!question.isShowOption" v-if="question?.options?.length" @click="question.isShowOption = true">
                    <SvgIcon type="mdi" :size="16" :path="icons.eye"/>
                  </button>
                  <button v-show="question.isEdit" @click="updateQuestionRequest(question)">
                    <SvgIcon type="mdi" :size="16" :path="icons.ok"/>
                  </button>
                  <button v-show="!question.isEdit" @click="startEditQuestion(question)">
                    <SvgIcon type="mdi" :size="16" :path="icons.pencil" />
                  </button>
                  <button @click="openDeleteQuestionModal(question)">
                    <SvgIcon type="mdi" :size="16" :path="icons.delete"/>
                  </button>
                </div>
                <SvgIcon v-show="question && question.isLoading" type="mdi" :size="16" :path="icons.loading" class="loading"/>
              </div>
              <div class="options-title" v-if="question.options && question.options.length">{{question.isShowOption?'Варианты ответа:':'[Нажмите на глаз для просмотра вариантов ответа]'}}</div>
              <div class="options-title" v-else>вариантов ответа пока нет</div>
              <div class="options" v-if="question.options" v-show="question.isShowOption">
                <div 
                  v-for="(option,index) in question.options.sort((a, b) => a.order - b.order)" :key="option.uuid"
                  :class="['option',{'drag-top': option.isDragTop},{'drag-bottom': option.isDragBottom}]"
                  draggable="true"
                  @dragstart="onDragStartOption($event, index)"
                  @dragover="onDragOverOption($event, index, question)"
                  @drop="onDropOption($event, index, question)"
                >
                  <input  v-show="option.isEdit" v-model="option.name" type="text" class="update-option-name">
                  <select
                    v-show="option.isEdit"
                    :value="option.type"
                    @change="(event) => setOptionType(event.target.value, option)"
                  >
                    <option
                      v-for="type in optionTypes"
                      :key="type"
                      :value="type"
                    >
                      {{ type }}
                    </option>
                  </select>
                  <div v-show="!option.isEdit" class="option-title text-wrapper">
                    {{option.name}}
                  </div>
                  <div v-show="!option.isEdit" class="option-type">
                    {{option.type}}
                  </div>
                  <div class="actions" v-show="!option.isLoading">
                    <button v-show="option.isEdit" @click="updateOptionRequest(question, option)">
                      <SvgIcon  type="mdi" :size="13" :path="icons.ok"/>
                    </button>
                    <button v-show="!option.isEdit" @click="startEditOption(option)">
                      <SvgIcon  type="mdi" :size="13" :path="icons.pencil"  />
                    </button>
                    <button @click="deleteOptionRequest(question,option)">
                      <SvgIcon type="mdi" :size="13" :path="icons.delete" />
                    </button>
                  </div>
                  <SvgIcon v-show="option.isLoading" type="mdi" :size="16" :path="icons.loading" class="loading"/>
                </div>
              </div>
              <div class="add-option" v-if="question.isShowOption || !question.options ||  !question.options.length">
                <button @click="addOptionRequest(question)" :disabled="question.isOptionCreating">
                  <SvgIcon type="mdi" :size="20" :path="icons.plus" v-show="!question.isOptionCreating" />
                  <SvgIcon type="mdi" :size="16" :path="icons.loading" v-show="question.isOptionCreating" class="loading"/>
                </button>
              </div>
          </div>
        </div>
        <p class="empty-question" v-else>Вопросов пока нет</p>
      </div>

      <p class="sub-title">Добавить вопрос</p>
      <div class="add-question">
        <Input v-model="questionName" placeholder="Новый вопрос" type="text"/>
        <Button @click="addQuestionRequest" class="button" small gray :loading="addQuestionLoading" :disabled="addQuestionLoading">
          {{addQuestionLoading?'':'Добавить'}}
        </Button>
      </div>

      <div class="actions">
        <Button @click="close" class="button" small gray :loading="loading" :disabled="loading">
          Отмена
        </Button>
      </div>
      
    </div>
  </Modal>
</template>

<script>
import { mdiPlus, mdiDelete, mdiPencil, mdiCheck, mdiLoading, mdiEyeClosed, mdiEye } from '@mdi/js';
import Modal from "@/components/Modal.vue";
import Input from "@/components/Input.vue";
import Alert from "@/components/Alert.vue"
import Select from "@/components/Select.vue";
import Button from '@/components/Button.vue'
import { mapActions, mapGetters } from 'vuex';
import SvgIcon from "@jamescoyle/vue-icon";
// import Switch from '../Switch.vue';

export default {
  components: {
    Modal,
    Input,
    Select,
    Button,
    Alert,
    SvgIcon,
    // Switch
  },
  props: {
    ...Modal.props,
    survey: Object
  },
  data() {
    return {
      timeoutPointer: null,
      expiredAtString:null,
      activatedAtString:null,
      surveyData:{},
      isDraggingOption: false,
      isQuestionDeleting: false,
      icons:{
        plus: mdiPlus,
        delete: mdiDelete,
        pencil: mdiPencil,
        ok: mdiCheck,
        loading: mdiLoading,
        eye: mdiEye,
        closedEye: mdiEyeClosed
      },
      questionName: '',
      success:'',
      error:'',
      loading: false,
      selectedDeleleteQuestion: null,
      addQuestionLoading: false,
      draggedItemIndex: -1,
      optionTypes: ['RADIO', 'CHECKBOX', 'RATING', 'TEXT'],
      timeRange: [
        {value: 'ONCE', name:'Только один раз'},
        {value: 'ONCE_DAY', name:'Раз в день'},
        {value: 'ONCE_WEEK', name:'Раз в неделю'},
        {value: 'ONCE_MONTH', name:'Раз в месяц'},
        {value: 'ONCE_THREE_MONTHS', name:'Раз в 3 месяця'},
        {value: 'ONCE_SIX_MONTHS', name:'Раз в 6 месяцев'},
        {value: 'ONCE_YEAR', name:'Раз в год'},
      ]
    };
  },
  mounted() {
    this.fetchConstants()
    this.surveyData = {...this.survey}
    this.expiredAtString = this.survey.expiredAt
    this.activatedAtString = this.survey.activatedAt
  },
  methods: {

    ...mapActions([
      'updateSurvey', 
      'addQuestion', 
      'deleteQuestion', 
      'updateQuestion', 
      'fetchConstants',
      'deleteOption',
      'addOption',
      'updateOption'
    ]),

    startEditOption(option){
      option.isEdit = true
    },

    setOptionType(optionType, option){
      option.type = optionType
    },

    updateOptionRequest(question, option){
      console.log('updateOptionRequest',option)
      option.isLoading = true
      this.updateOption({
        surveyUuid: this.surveyData.uuid,
        questionUuid: question.uuid,
        option
      }).then(() => {
        this.setSuccess('вариант ответа успешно обновлён')
      }).catch(error => {
        console.error(error)
        this.setError(error)
      }).finally(() => {
        option.isLoading = false
        option.isEdit = false
      })
    },

    addOptionRequest(question){
      question.isShowOption = true
      question.isOptionCreating = true
      question.options = question.options?question.options:[]
      this.addOption({
        surveyUuid: this.surveyData.uuid, 
        questionUuid: question.uuid,
        option: {
          name: `вариант ответа ${question.options.length + 1}`,
          type: 'CHECKBOX'
        }
      }).then(() => {
        this.setSuccess('Вариант ответа успешно добавлен')
      }).catch(error => {
        console.error(error)
        this.setError('Произошла ошибка')
      }).finally(() => {
        question.isOptionCreating = false
      })
    },

    deleteOptionRequest(question, option){
      option.isLoading = true
      this.deleteOption({
        surveyUuid: this.surveyData.uuid, 
        questionUuid: question.uuid,
        option
      }).then(()=>{
        this.setSuccess('Вариант ответ успешно удалён')
      }).catch(error=>{
        console.error(error)
        this.setError('Произошла ошибка')
      }).finally(()=>{
        option.isLoading = false
      })
    },

    //
    onDragStartOption(event, index){
      event.stopPropagation();
      this.isDraggingOption = true
      this.draggedItemIndex = index;
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('dragIndex', index);
    },

    onDragOverOption(event, index, question){
      event.stopPropagation();
      event.preventDefault();

      const draggedOverItem = event.currentTarget;
      const bounding = draggedOverItem.getBoundingClientRect();
      const offset = bounding.top + bounding.height / 2;
      const option = question.options[index]
      this.clearDragEffect()

      if (event.clientY > offset) {
        // Element is below
        option.isDragBottom = true;
        option.isDragTop = false;
      } else {
        // Element is above
        option.isDragBottom = false;
        option.isDragTop = true;
      }
    },

    onDropOption(event, index, question) {
      event.stopPropagation()
      const draggedOverItem = event.currentTarget;
      const bounding = draggedOverItem.getBoundingClientRect();
      const offset = bounding.top + bounding.height / 2;
      const dragIndex = parseInt(event.dataTransfer.getData('dragIndex'));
      const option = question.options[index]

      let order = 0;
      let elemOrder = option.order
      let nextElemOrder = 0

      if (event.clientY > offset) {
        console.log('ниже')
        if(index + 1 < question.options.length){
          nextElemOrder = question.options[index+1].order
        }else{
          nextElemOrder = this.constants.MAX_OPTION_ORDER
        }
        
      } else {
        console.log('выше')
        if(index>0){
          nextElemOrder = question.options[index-1].order
        }else{
          nextElemOrder = this.constants.MIN_OPTION_ORDER
        }
      }
      this.clearDragEffect()
      order = elemOrder + ((nextElemOrder - elemOrder)/2) 
      question.options[dragIndex].order = parseInt(order)
      console.log(elemOrder, order, nextElemOrder)
      this.updateOptionRequest(question, question.options[dragIndex])

      this.isDraggingOption = false
    },
    //

    onDragStart(event, index){
      if(this.isDraggingOption)
        return
      this.draggedItemIndex = index;
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('dragIndex', index);
    },

    onDragOver(event, index){
      if(this.isDraggingOption)
        return
      this.clearDragEffect();
      event.preventDefault();

      const draggedOverItem = event.currentTarget;
      const bounding = draggedOverItem.getBoundingClientRect();
      const offset = bounding.top + bounding.height / 2;

      if (event.clientY > offset) {
        // Element is below
        this.surveyData.questions[index].isDragBottom = true;
        this.surveyData.questions[index].isDragTop = false;
      } else {
        // Element is above
        this.surveyData.questions[index].isDragBottom = false;
        this.surveyData.questions[index].isDragTop = true;
      }
    },

    onDrop(event, index) {
      console.log('question drop')
      if(this.isDraggingOption)
        return
      const draggedOverItem = event.currentTarget;
      const bounding = draggedOverItem.getBoundingClientRect();
      const offset = bounding.top + bounding.height / 2;
      const dragIndex = parseInt(event.dataTransfer.getData('dragIndex'));

      let order = 0;
      let elemOrder = this.surveyData.questions[index].order
      let nextElemOrder = 0

      if (event.clientY > offset) {
        if(index + 1 < this.surveyData.questions.length){
          nextElemOrder = this.surveyData.questions[index+1].order
        }else{
          nextElemOrder = this.constants.MAX_QUESTION_ORDER
        }
        
      } else {
        if(index>0){
          nextElemOrder = this.surveyData.questions[index-1].order
        }else{
          nextElemOrder = this.constants.MIN_QUESTION_ORDER
        }
      }
      this.clearDragEffect()
      order = elemOrder + ((nextElemOrder - elemOrder)/2) 
      this.surveyData.questions[dragIndex].order = parseInt(order)
      this.updateQuestionRequest(this.surveyData.questions[dragIndex])
    },

    clearDragEffect(){
      this.surveyData.questions.forEach((q,index) => {
        this.surveyData.questions[index].isDragBottom = false
        this.surveyData.questions[index].isDragTop = false

        if(q.options){
          q.options.forEach(o => {
            o.isDragBottom = false
            o.isDragTop = false
          })
        }
      });
    },

    updateSurveyRequest(){
      this.loading = true
      this.updateSurvey({
        uuid: this.surveyData.uuid,
        name: this.surveyData.name,
        expiredAt: this.surveyData.expiredAt,
        activatedAt: this.surveyData.activatedAt
      }).then(() => {
        this.setSuccess('Данные успешно обновлены')
      }).finally(()=>{
        this.loading = false
      })
    },

    startEditQuestion(question){
      question.isEdit = true
    },

    updateQuestionRequest(question){
      question.isLoading = true
      this.updateQuestion({surveyUuid: this.surveyData.uuid, question}).then(() => {
        this.setSuccess('Вопрос успешно обновлён')
      }).catch(error => {
        console.error(error)
        this.setError('Произошла ошибка')
      }).finally(()=>{
        question.isLoading = false
        question.isEdit = false
      })
    },

    close(){
      this.$emit('close')
    },

    validateQuestion(){
      const questionNameRegex = /^[a-zA-Zа-яА-Я0-9\s.,!?]{3,255}$/
      if(!this.questionName.match(questionNameRegex)){
        this.setError(`Именование вопроса должно соблюдать эти правила: -латиница\n-кирилица\n-цифры\n-точка, запятая, ! и ?\n-длина от 3 до 255 символов`)
      }
    },

    setError(error){
      this.success = ''
      this.error = error
    },

    setSuccess(success){
      this.success = success

      window.clearTimeout(this.timeoutPointer)
      this.timeoutPointer = setTimeout(() => {
        this.success = ''
      },5000)
    },

    addQuestionRequest(){
      this.validateQuestion()

      if(!this.error){
        let order = 0
        if(this.surveyData.questions.length){
          order = parseInt(this.surveyData.questions[this.surveyData.questions.length - 1].order + (this.constants.MAX_QUESTION_ORDER - this.surveyData.questions[this.surveyData.questions.length - 1].order)/2)
        }
        this.addQuestionLoading = true
        this.addQuestion({surveyUuid: this.surveyData.uuid, value: this.questionName, order}).then(() => {
          this.setSuccess('Вопрос успешно добавлен')
          this.questionName = ''
        }).finally(() => {
          this.addQuestionLoading = false
        })
      }
    },

    openDeleteQuestionModal(question){
      this.selectedDeleleteQuestion = question
    },

    deleteQuestionRequest(){
      this.isQuestionDeleting = true
      this.deleteQuestion({uuid: this.selectedDeleleteQuestion.uuid, surveyUuid: this.surveyData.uuid}).then(()=>{
        this.setSuccess('Вопрос успешно удалён')
        this.closeDeleteQuestion()
      }).catch(error=>{
        console.error(error)
        this.setError('При удалении вопроса произошла ошибка')
      }).finally(() => {
        this.isQuestionDeleting = false
      })
    },

    closeDeleteQuestion(){
      this.selectedDeleleteQuestion = null
    },

    validate(){
      // if(this.)
    },

    minStartAt() {
      const currentDate = new Date();
      return currentDate.toISOString().substr(0, 10);
    },
  },
  computed: {
    ...mapGetters(['constants'])
  },

  watch:{
    'surveyData.name'(){
      this.updateSurveyRequest()
    },
    'surveyData.expiredAt'(){
      this.updateSurveyRequest()
    },
    'surveyData.activatedAt'(){
      this.updateSurveyRequest()
    },
    expiredAtString(){
      this.surveyData.expiredAt = new Date(this.expiredAtString) 
    },
    activatedAtString(){
      this.surveyData.activatedAt = new Date(this.activatedAtString)
    },
    questionName(){
      this.error = ''
    }
  }
};
</script>

<style lang="scss" scoped>

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.loading {
  animation: 1s spin linear infinite;
}

.survey-modal{
  margin-bottom: 150px;
  padding: 40px;
  width: 580px;
  height: min-content;
  border-radius: 9px;
  background: rgba(#1f2023, 0.5);
  backdrop-filter: blur(12px);
  color: white;

  .title{
    margin-bottom: 20px;
  }

  .sub-title{
    margin-bottom: 5px;
  }

  .survey{

    padding: 20px 0px;

    input{
      margin-bottom: 20px;

      &:last-child{
        margin: 0;
      }
    }
  }


  .drag-top::before,
  .drag-bottom::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: white;
  }

  .drag-top::before {
    top: 0;
  }

  .drag-bottom::after {
    bottom: 0;
  }

  .text-wrapper{
    word-break: break-all;
  }

  .questions{
    margin-bottom: 20px;
    padding: 20px 0px;
    background: #1F2023;
    position: relative;

    .empty-question{
      padding-left: 20px;
      color: #575a64;
    }

    .question{
      cursor: pointer;
      padding: 10px 20px;
      box-sizing: border-box;
      position: relative;
      &:hover{
        background: #2f3135;

        .options{
          .option{
            position: relative;
            .actions{
              opacity: 1;
            }
          }
        }
      }

      .question-title-wrapper{
        display: flex;
        justify-content: space-between;

        .questio-edit-value{
          background: transparent;
          border: none;
          border-bottom: 1px solid gray;
          width: 300px;
          color: white;
        }

        .question-title{
          font-size: 18px;
          font-weight: 700;
          max-width: 300px;
          word-break: break-all;
        }
      }

      
      .options-title{
        font-size: 14px;
        color: #575a64;
        font-style: italic;
      }
      .options{
        padding-top: 5px;
        display: flex;
        flex-direction: column;

        .option{
          padding: 5px;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .update-option-name{
            background: transparent;
            border: none;
            border-bottom: 1px solid gray;
            color: white;
          }

          &:hover{
            background: #6e7079;

            .option-title, .option-type{
              color: white;
            }
            .actions{
              button{
                color: white;
              }
            }
          }

          select{
            background: transparent;
            border: none;
            border-bottom: 1px solid gray;
            color: white;
            cursor: pointer;

            option{
              color: black
            }
          }

          .option-title{
            width: 200px;
            color: #8e92a0;
          }

          .option-type{
            color: #8e92a0;
            justify-self: flex-end;
          }

          .actions{
            button{
              color: #8e92a0;
            }
            opacity: .4;
          }
        }
      }
    }

    .delete-question-modal{
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      background: rgba(#1f2023, 0.8);
      backdrop-filter: blur(10px);
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .body{
        width: 100%;
        padding: 20px;
        background: #1f2023;

        .title{
          word-break: break-all;
        }

        .actions{
          display: flex;

          .button:last-child{
            margin-left: 10px;
          }
        }
      }
    }
  }

  .add-question{
    margin-bottom: 20px;
    padding: 20px;
    background: #1F2023;

    padding-top: 20px;
    display: flex;

    button{
      margin-left: 10px;
    }
  }

  .button{
    svg{
      margin-left: 10px;
    }
  }
}

.add-option{
  button{
    color: white;
    border: none;
    background: transparent;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover{
      background: #575a64;
    }
  }
}

.actions{
  display: flex;
  button{
    border-radius: 4px;
    cursor: pointer;
    color: white;
    background: transparent;
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
      background: #575a64;
    }
  }
}
</style>