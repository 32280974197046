const ReleaseStatus = {
  ALL: {
    key: 'ALL',
    title: 'Все',
    color: 'gray',
  },
  DRAFT: {
    key: 'DRAFT',
    title: 'Черновик',
    color: 'gray',
  },
  DENIED: {
    key: 'DENIED',
    title: 'Отклонен',
    color: 'red',
  },
  PENDING: {
    key: 'PENDING',
    title: 'Новый',
    color: 'orange',
  },
  MODERATED: {
    key: 'MODERATED',
    title: 'В ожидании',
    color: 'yellow',
  },
  PUBLISHED: {
    key: 'PUBLISHED',
    title: 'Опубликован',
    color: 'green',
  },
};

export default ReleaseStatus;
