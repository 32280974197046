<template>
    <div class="audio-player">
        <div class="previews next-track-anim">
            <div class="previews__loader" v-if="!audioFile?.base64">
                <LoadingLogo class="previews__loader-logo" />
            </div>
            <div class="preview active">
                <img :src="this.file(this.coverUuid)?.base64 ?? require('@/assets/placeholder.png')" alt="">
            </div>
            <!-- <div class="preview prev">
                <img src="require('@/assets/placeholder.png')" alt="">
            </div>
            <div class="preview next">
                <img src="require('@/assets/placeholder.png')" alt="">
            </div> -->
        </div>
        <div class="body">
            <div class="controls">
                <div class="btn" style="transform: rotateY(180deg);" @click="prev">
                    <img src="@/assets/icons/next-track.svg" alt="">
                </div>
                <div 
                    class="btn" 
                    v-if="!isPlaying"
                    @click="play"
                >
                    <img src="@/assets/icons/play.svg" alt="">
                </div>
                <div 
                    class="btn" 
                    v-else
                    @click="pause"
                >
                    <img src="@/assets/icons/pause.svg" alt="">
                </div>
                <div class="btn" @click="next">
                    <img src="@/assets/icons/next-track.svg" alt="">
                </div>
                <h3 class="track-name">
                    {{ 
                        currentTrack
                            ? currentTrack.title
                            : 'Select track'
                    }}
                </h3>
            </div>
            <div class="timeline" @mousedown.stop>
                <input 
                    :style="`background-size:${progress}% 100%;`"
                    type="range" 
                    id="volume" 
                    name="volume" 
                    min="0" 
                    :value="seconds * 100" 
                    :max="duration * 100"
                    @input="rewindHandler"
                    @change="rewindHandler"
                >
            </div>
        </div>
        <audio
            @timeupdate="onChangeTime"
            @canplay="onCanPlay"
            @ended="nextTrack"
            ref="audio"
        >
            <source 
                v-if="audioFile"
                type="audio/wav"
                :src="audioFile.base64"
            >
        </audio>

    </div>
</template>

<script>
import LoadingLogo from '@/components/LoadingLogo.vue'
import {mapMutations, mapActions, mapGetters} from 'vuex'

export default {
    components: {
        LoadingLogo
    },

    data(){
        return {
            seconds: 0,
            duration: 0,
            coverSrc: require('@/assets/placeholder.png'),
        }
    },

    methods:{
        ...mapActions(['fetchFile']),
        ...mapMutations(['nextTrack', 'prevTrack', 'setIsPlaying']),

        play(){
            this.$refs.audio.play();
            this.setIsPlaying(true);
        },

        pause(){
            this.$refs.audio.pause();
            this.setIsPlaying(false);
        },

        prev () {
            this.setTime(0);
            this.prevTrack();
            this.play();
        },

        next () {
            this.nextTrack();
            this.pause();
            this.play();
        },

        setTime (time) {
            this.$refs.audio.currentTime = time;
        },

        onChangeTime(event){
            this.seconds = event.target.currentTime
        },

        onCanPlay(event){
            this.duration = event.target.duration;
            if (this.isPlaying) this.play();
        },

        rewindHandler (e) {
            this.setTime(e.target.value / 100);
        }
    },

    computed:{
        ...mapGetters(['file', 'queue', 'currentTrackIndex', 'isPlaying', 'coverUuid']),

        currentTrack () {
            return this.queue[this.currentTrackIndex];
        },

        audioFile () {
            const uuid = this.currentTrack?.wav_uuid;
            return this.file(uuid);
        },

        progress(){
            if (this.duration == 0) return 0;

            return (this.seconds / this.duration) * 100
        },
    },

    watch:{
        getTrack(){
            alert('new track!')
        },
        audioFile(){
            this.$refs.audio.load();
        },
        currentTrackIndex () {
            this.play();
        },
        isPlaying () {
            this.isPlaying ? this.play() : this.pause();
        },
    }
}
</script>

<style lang="scss" scoped>
    .audio-player{
        border-radius: 9px;
        background: rgba(0, 0, 0, .7);
        backdrop-filter: blur(12px);
        display: flex;
        align-items: center;
        padding: 24px;

        .previews{
            position: relative;

            &__loader {
                width: 48px;
                height: 48px;
                background: rgba(0, 0, 0, .6);
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 0;
            }

            &__loader-logo {
                width: 65%;
                height: 65%;
            }

            .prev, .next{
                position: absolute;
                scale: .7;
                z-index: -1;
                top: 0;
                opacity: .5;
            }

            .prev{
                left: -45%;
            }
            .next{
                left: 45%;
            }

            .preview{
                width: 48px;
                height: 48px;
                border-radius: 9px;
                overflow: hidden;
                margin-right: 25px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    user-select: none;
                    -webkit-user-select: none;
                    -webkit-user-drag: none;
                }

            }
        }

        .body{
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .track-name{
                margin: 2px 0 0 16px;
                max-width: 220px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .controls{
                display: flex;
                margin-bottom: 5px;

                .btn{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    img{
                        width: 25px;
                        height: 25px; 
                    }
                }
            }
        }
    }

    .next-track-anim{
        position: relative;

        .prev{
            position: absolute;
            scale: 7;
            z-index: -1;
            top: 0;
            opacity: .5;
            left: 45%;
        }
    }

    .timeline{
        input[type="range"] {
            -webkit-appearance: none;
            margin-right: 15px;
            width: 200px;
            height: 7px;
            background: #61646a;
            border-radius: 5px;
            background-image: linear-gradient(red, red);
            background-repeat: no-repeat;
            cursor: pointer;
        }

        /* Input Thumb */
        input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background: red;
            transition: background .3s ease-in-out;
            // display: none;
        }

        input[type="range"]::-moz-range-thumb {
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: red;
            cursor: ew-resize;
            transition: background .3s ease-in-out;
        }

        input[type="range"]::-ms-thumb {
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: red;
            cursor: ew-resize;
            transition: background .3s ease-in-out;
        }

        input[type="range"]::-webkit-slider-thumb:hover {
            background: #ff0200;
        }

        input[type="range"]::-moz-range-thumb:hover {
            background: #ff0200;
        }

        input[type="range"]::-ms-thumb:hover {
            background: #ff0200;
        }

        /* Input Track */
        input[type=range]::-webkit-slider-runnable-track  {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }

        input[type=range]::-moz-range-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }

        input[type="range"]::-ms-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }
    }
</style>