<template>
    <form @submit.prevent="$emit('search')" class="search-input-label">
        <button type="submit">
            <img src="@/assets/icons/search.svg" alt="">
        </button>
        <input
            :value="modelValue"
            v-bind="$attrs"
            @input="$emit('update:modelValue', $event.target.value)"
            class="search-input"
        >
    </form>
</template>
<script>
export default {
    props: {
        modelValue: {
            type: String,
            default: ''
        }
    }
}
</script>
<style lang="scss" scoped>

    .search-input-label{
        width: 100%;
        position: relative;
        height: 52px;

        input{
            padding: 17px 14px;
            background: #3f4146;
            color: white;
            width: 100%;
            border: none;
            border-radius: 8px;
        }

        button{
            width: 52px;
            position: absolute;
            right: 0px;
            background: transparent;
            border: none;
            height: 52px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            img{
                width: 12px;
                height: 14px;
            }

            &:active{
                img{
                    transform: translateY(2px);
                }
            }
        }
    }
</style>