import axios from 'axios';

const artists = {
    state: () => ({
        artists: [],
        artistsRoles: []
    }),
    actions: {
        fetchAllArtists({commit}){
            return new Promise( (resolve, reject) => {
                axios.get('/artists').then(response => {
                    commit('replaceArtists', response.data)
                    resolve(response)
                }).catch(e => reject(e))
            })
        },

        fetchArtist({commit}, artistUUID){
            return new Promise( (resolve, reject) => {
                axios.get(`/artist/${artistUUID}`).then(response => {
                    commit('addArtist', response.data)
                    resolve(response)
                }).catch(e => reject(e))
            })
        },

        fetchArtistsRoles({commit}){
            return new Promise((resolve,reject) => {
                axios.get('/artist-roles').then(response => {
                    commit('replaceArtistsRoles',Object.keys(response.data))
                    resolve(response)
                }).catch(error => reject(error))
            })
        },

        updateArtist({commit}, {uuid, name, spotify_id, apple_id, description}){
            return new Promise((resolve, reject) => {
                axios.patch(`/artist/${uuid}`,{
                    name,
                    spotify_id,
                    apple_id,
                    description
                }).then(response => {
                    commit('updateArtist',{uuid, name, spotify_id, apple_id, description})
                    resolve(response)
                }).catch(error => reject(error))
            })
        }
    },
    mutations: {
        addArtist(state, artist){
            state.artists.push(artist);
        },

        replaceArtists(state, artists){
            state.artists = artists
        },

        replaceArtistsRoles(state, artistRoles){
            state.artistRoles = artistRoles
        },

        updateArtist(state){
            console.log(state)
        }
    },
    getters: {
        getArtist: state => uuid => state.artists.find(artist => artist.uuid == uuid),

        getArtistsRoles: state => state.artistRoles
    },
};

export default artists;
