import { createRouter, createWebHistory } from 'vue-router';
import store from "../store"
import Layout from '@/layouts/MainLayout'
import WelcomePage from '@/views/WelcomePage'
import MainPage from '@/views/MainPage'
import UsersPage from '@/views/UsersPage'
import SurveyPage from '@/views/SurveyPage'
import SurveyAnswersPage from '@/views/SurveyAnswersPage'

const routes = [
    {
        path: '/',
        name: 'Welcome',
        component: WelcomePage,
        meta:{auth:false}
    },
    {
        path: '/',
        component: Layout,
        children:[
            {
                path: '/main/:release_uuid?',
                name: 'Main',
                component: MainPage
            },
            {
                path: '/users/:user_uuid?',
                name: 'Users',
                component: UsersPage
            },
            {
                path: '/survey/:survey_uuid?',
                name: 'Survey',
                component: SurveyPage
            },
            {
                path: '/survey/:survey_uuid/answers',
                name: 'SurveyAnswers',
                component: SurveyAnswersPage
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to) => {

    //проверяет нужна ли аутентификация данной странице
    if(to.meta.auth == false)
        return true

    //если нужна
    else{
        let accessToken = null; 
        let tokenPayload = null; 
        let tokenLifeTime = null; 
        let presentTime = null;
        
        //получает токен 
        accessToken = localStorage.getItem('access_token');

        if (accessToken) {
            //TODO: REPLACE TO Buffer.toString() -> Reference Error:: Buffer is not defined???
            tokenPayload = atob(accessToken.split('.')[1])
            tokenLifeTime = JSON.parse(tokenPayload).exp;
            presentTime = parseInt((new Date().getTime()) / 1000);
        }

        //проверяет токен на валидность
        if (!accessToken || (presentTime > tokenLifeTime)) {
            console.log('router:: токен не валидный')
            console.log('router:: вызываем store');

            if (!store.getters.pendingUpdate_RefreshTokens) {
                console.log('router:: слушателя не обнаружено');
                console.log('router:: вызываем store вручную');
                try{
                    console.log('router:: успешно store обновил токены');
                    await store.dispatch('refresh')
                    return true;
                }catch{
                    console.log('router:: store не обновил токены');
                    router.push({ name: 'Welcome' });
                }
            } 
            else {
                console.log('router:: слушатель обнаружен');
                try{
                    await  store.getters.pendingUpdate_RefreshTokens
                    console.log('router:: успешно store обновил токены 2');
                    return true;
                }catch{
                    console.log('router:: store не обновил токены 2');
                    router.push({ name: 'Welcome' });
                }
            }
        }
        else{
            console.log('router:: токен валидный');
            return true
        }

        console.log('router:: почему мы попали сюда?');
    }

    return false
})


export default router