import axios from 'axios';

const survey = {
  state: () => ({
    surveys:[],
    constants:{
      MAX_OPTION_COUNT: 20,
      MAX_QUESTION_COUNT: 100,
      MAX_OPTION_ORDER: 32767,
      MAX_QUESTION_ORDER: 32767,
      MIN_OPTION_ORDER: -32768,
      MIN_QUESTION_ORDER: -32768,
      OptionTypes: {
          RADIO: "RADIO",
          CHECKBOX: "CHECKBOX",
          RATING: "RATING",
          TEXT: "TEXT"
      },
      SurveySlugRegExp: /^[A-Z0-9]{1,128}$/
    }
  }),
  actions: {
    fetchConstants: function ({commit}){
      return new Promise((resolve, reject) => {
        axios.get('/constants').then(response => {
          commit('setSurveyConstants',response.data)
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      }); 
    },

    fetchAllSurveys: function ({ commit }, options) {
      return new Promise((resolve, reject) => {
        axios.get('/survey', {
          params: {
            page: options?.page ?? 1,
            take: options?.take ?? 50,
            order: options?.order ?? undefined,
            search: options?.search ?? undefined,
          },
        }).then(response => {
          commit('updateSurveys',response.data.data)
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    fetchSurvey({commit}, uuid){
      return new Promise((resolve, reject) => {
        axios.get(`/survey/${uuid}`).then(response => {
          commit('setSurvey', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    createSurvey: function ({commit}, {slug, name, expiredAt}){
      return new Promise((resolve, reject) => {
        axios.post('/survey',{
          slug: slug?? undefined ,
          name,
          expiredAt: expiredAt? expiredAt.toISOString()?? undefined : undefined
        }).then(response => {
          commit('setSurvey', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateSurvey({commit},{uuid, name, expiredAt, activatedAt}){
      return new Promise((resolve, reject) => {
        console.log('expiredAt', expiredAt)
        axios.patch(`/survey/${uuid}`,{
          name,
          expiredAt: expiredAt? expiredAt.toISOString()?? undefined : undefined,
          activatedAt: activatedAt? activatedAt.toISOString()?? undefined : undefined
        }).then(response => {
          const survey = response.data
          commit('updateSurvey', {uuid, name: survey.name, expiredAt: survey.expiredAt})
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteSurvey({commit},uuid){
      return new Promise((resolve, reject) => {
        axios.delete(`/survey/${uuid}`).then(response => {
          commit('deleteSurvey',uuid)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    addQuestion({commit},{surveyUuid, value, order}){
      return new Promise((resolve, reject) => {
        axios.post(`/survey/${surveyUuid}/question`,{
          value,
          order
        }).then(response => {
          console.log(response.data)
          commit('addQuestion',{surveyUuid, question: response.data})
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteQuestion({commit},{uuid, surveyUuid}){
      return new Promise((resolve, reject) => {
        axios.delete(`/question/${uuid}`).then(response => {
          commit('deleteQuestion', {uuid, surveyUuid})
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    updateQuestion({commit},{surveyUuid, question}){
      return new Promise((resolve, reject) => {
        axios.patch(`/question/${question.uuid}`,{
          value: question.value,
          order: question.order
        }).then(response => {
          commit('updateQuestion', {question: response.data, surveyUuid})
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    addOption(
      {commit},
      {
        surveyUuid, 
        questionUuid,
        option
      }
    ){
      return new Promise((resolve, reject) => {
        axios.post(`/question/${questionUuid}/option`,{
          order: option.order,
          type: option.type,
          name: option.name
        }).then(response => {
          commit('addOption',
          {
            surveyUuid, 
            questionUuid,
            option: response.data
          })
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    updateOption(
      {commit},
      {
        surveyUuid, 
        questionUuid,
        option
      }
    ){
      return new Promise((resolve, reject) => {
        console.log('updateOption',option)
        axios.patch(`/option/${option.uuid}`,{
          name: option.name,
          order: option.order,
          type: option.type
        }).then(response => {
          commit('updateOption', {surveyUuid, questionUuid, option})
          resolve(response)
        }).catch(error=>reject(error))
      })
    },
    deleteOption(
      {commit},
      {
        surveyUuid, 
        questionUuid,
        option
      }
    ){
      return new Promise((resolve, reject) => {
        axios.delete(`/option/${option.uuid}`).then(response => {
          commit('deleteOption',{surveyUuid, questionUuid, optionUuid: response.data.uuid} )
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    fetchSurveyUsers({commit},survey){
      return new Promise((resolve,reject)=>{
        axios.get(`/survey/${survey.uuid}/users`).then(response => {
          // commit('addAnswerUsers', response.data)
          console.log(commit)
          resolve(response)
        }).catch(error => reject(error))
      })
    }
  },
  mutations: {
    updateSurveys(state, surveys){
      state.surveys = surveys
    },
    setSurveyConstants(state, constants){
      state.constants = constants
      const regexParts = constants.SurveySlugRegExp.match(/\/(.+)\/([a-z]*)/i);
      state.constants.SurveySlugRegExp = new RegExp(regexParts[1], regexParts[2]);
    },
    setSurvey(state,survey){
      const idx = state.surveys.findIndex(stateSurvey => stateSurvey.uuid == survey.uuid )
      if(idx != -1){
        state.surveys[idx] = {...survey}
      }else{
        state.surveys.push(survey)
      }
    },
    updateSurvey(state, {uuid, name, expiredAt}){
      const idx = state.surveys.findIndex(stateSurvey => stateSurvey.uuid == uuid )
      if(idx != -1){
        state.surveys[idx].name = name
        state.surveys[idx].expiredAt = new Date(expiredAt)
      }
    },
    deleteSurvey(state,uuid){
      const idx = state.surveys.findIndex(stateSurvey => stateSurvey.uuid == uuid )
      if(idx != -1){
        state.surveys.splice(idx, 1)
      }
    },
    addQuestion(state, {surveyUuid, question}){
      const idx = state.surveys.findIndex(stateSurvey => stateSurvey.uuid == surveyUuid )
      if(idx != -1){
        state.surveys[idx].questions.push(question)
      }
    },
    deleteQuestion(state, {surveyUuid, uuid}){
      const idx = state.surveys.findIndex(stateSurvey => stateSurvey.uuid == surveyUuid )
      if(idx != -1){
        const idxQuestion = state.surveys[idx].questions.findIndex(question => question.uuid == uuid)
        if(idxQuestion != -1){
          state.surveys[idx].questions.splice(idxQuestion,1)
        }
      }
    },
    updateQuestion(state, {surveyUuid, question}){
      const idx = state.surveys.findIndex(stateSurvey => stateSurvey.uuid == surveyUuid )
      if(idx != -1){
        const idxQuestion = state.surveys[idx].questions.findIndex(surveyQuestion => surveyQuestion.uuid == question.uuid)
        if(idxQuestion != -1){
          state.surveys[idx].questions[idxQuestion].value = question.value
          state.surveys[idx].questions[idxQuestion].order = question.order
        }
      }
    },
    addOption(state, {surveyUuid, questionUuid, option}){
      const idx = state.surveys.findIndex(stateSurvey => stateSurvey.uuid == surveyUuid )
      if(idx != -1){
        const idxQuestion = state.surveys[idx].questions.findIndex(surveyQuestion => surveyQuestion.uuid == questionUuid)
        state.surveys[idx].questions[idxQuestion].options.push(option)
      }
    },
    updateOption(state,{surveyUuid, questionUuid, option}){
      console.log('updateOptionMutation',option)
      const idx = state.surveys.findIndex(stateSurvey => stateSurvey.uuid == surveyUuid )
      if(idx != -1){
        const idxQuestion = state.surveys[idx].questions.findIndex(surveyQuestion => surveyQuestion.uuid == questionUuid)
        if(idxQuestion != -1){
          const idxOption = state.surveys[idx].questions[idxQuestion].options.findIndex(questionOption => questionOption.uuid == option.uuid)
          if(idxOption != -1){
            state.surveys[idx].questions[idxQuestion].options[idxOption].name = option.name
            state.surveys[idx].questions[idxQuestion].options[idxOption].order = option.order
            state.surveys[idx].questions[idxQuestion].options[idxOption].type = option.type

            console.log('updateOptionState',state.surveys[idx].questions[idxQuestion].options[idxOption].order)
          }
        }
      }
    },
    deleteOption(state,{surveyUuid, questionUuid, optionUuid}){
      const idx = state.surveys.findIndex(stateSurvey => stateSurvey.uuid == surveyUuid )
      if(idx != -1){
        const idxQuestion = state.surveys[idx].questions.findIndex(surveyQuestion => surveyQuestion.uuid == questionUuid)
        if(idxQuestion != -1){
          const idxOption = state.surveys[idx].questions[idxQuestion].options.findIndex(option => option.uuid == optionUuid)
          if(idxOption != -1){
            state.surveys[idx].questions[idxQuestion].options.splice(idxOption, 1)
          }
        }
      }
    }
  },
  getters: {
    surveys: state => state.surveys,
    constants: state => state.constants,
    surveyByUuid: state => uuid => state.surveys.find(s => s.uuid == uuid )
  },
};

export default survey;
