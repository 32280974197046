<template>
  <Modal v-bind="$props" @close="closeUserModal">
    <div class="user" ref="user">
      <div class="user__main main">
        <div class="main__avatar">
          <img
            :src="
              file(user?.profile?.avatar_url)?.base64 ??
              require('@/assets/placeholder.png')
            "
            alt=""
          />
        </div>
        <div class="main__info">
          <div class="main__name">
            <span>
              {{ userName }}
            </span>
            <button class="main__copy" @click="copyToClipboard(userName)">
              <svg>
                <use
                  v-bind="{
                    'xlink:href':
                      require('@/assets/icons/all_icons.svg') + '#copy',
                  }"
                />
              </svg>
            </button>
          </div>
          <div class="main__email">
            <span>{{ user?.email }}</span>
            <div
              class="main__verification verification"
              v-if="user?.verified_at"
            >
              <svg class="verification__icon verification__icon--verified">
                <use
                  v-bind="{
                    'xlink:href':
                      require('@/assets/icons/all_icons.svg') + '#check',
                  }"
                />
              </svg>
              <!-- <svg class="verification__icon verification__icon--unverified" v-else>
                <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#cross'}" />
              </svg> -->
            </div>
            <button class="main__copy" @click="copyToClipboard(user?.email)">
              <svg>
                <use
                  v-bind="{
                    'xlink:href':
                      require('@/assets/icons/all_icons.svg') + '#copy',
                  }"
                />
              </svg>
            </button>
          </div>
          <div class="main__ban" v-if="isUserBanned">
            <SvgIcon type="mdi" :path="icons.banned" />
            <span>Причина блокировки: "{{ user?.ban_reason }}"</span>
          </div>
        </div>
        <div class="main__actions">
          <Button v-if="!isUserBanned" @click="ban" red> Заблокировать </Button>
          <Button v-else @click="unban" red> Разблокировать </Button>
          <Button v-if="!isAddingPurchase" @click="toggleIsAddingPurchase" blue>
            Добавить покупку
          </Button>
        </div>
      </div>
      <div class="user__releases" v-if="releasesList.length">
        <!-- <div class="user__header header">
          <div class="header__title">Все релизы</div>
        </div> -->
        <Table>
          <table>
            <thead>
              <th>Обложка</th>
              <th>Название</th>
              <th>Тип</th>
              <th>
                <button
                  :class="[
                    'table-sortable',
                    { 'table-sortable-desc': releasesPagination.options.order == 'DESC' },
                    { 'table-sortable-asc': releasesPagination.options.order == 'ASC' },
                  ]"
                  @click="toggleSort()"
                >
                  Дата
                </button>
              </th>
              <th v-show="!releasesPagination.options.status">Статус</th>
              <th>Действия</th>
            </thead>
            <tbody>
              <tr
                v-for="release in releasesList"
                :key="release.uuid"
                @click="selectRelease(release.uuid)"
              >
                <td>
                  <div class="table-cover table-center">
                    <img
                      :src="
                        file(release.cover_uuid)?.base64 ??
                        require('@/assets/placeholder.png')
                      "
                      alt="Cover"
                    />
                  </div>
                </td>
                <td>
                  {{ release.title }}
                </td>
                <td>
                  {{ release.type }}
                </td>
                <td>
                  <div class="info__date">
                    <div>
                      {{ release.release_date }}
                    </div>
                    <div class="info__transfered" v-if="release.transfered_at">
                      <SvgIcon type="mdi" :path="icons.arrowRight" />
                      {{ release.transfered_at }}
                    </div>
                  </div>
                </td>
                <td v-show="!releasesPagination.options.status">
                  <span class="info__status">
                    <StatusChip :status="release.status" />
                  </span>
                </td>
                <td>
                  <div class="table-actions">
                    <button @click.stop="copyReleaseURL(release)">
                      <img
                        src="@/assets/icons/copy.svg"
                        alt=""
                        class="actions__icon"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Table>
      </div>
      <div class="user__purchases purchases">
        <div class="purchases__block">
          <div class="user__header header">
            <div class="header__title">
              {{
                isAddingPurchase
                  ? "Что нужно выдать пользователю?"
                  : "Все покупки пользователя"
              }}
            </div>
            <div class="header__actions">
              <Button
                v-if="isAddingPurchase"
                class="header__action"
                @click="toggleIsAddingPurchase"
                gray
              >
                Отмена
              </Button>
            </div>
          </div>
          <div class="purchases__list" v-if="!isAddingPurchase">
            <div class="purchases__col" v-if="purchasesList.length">
              <div
                v-for="purchase in purchasesList"
                :key="purchase.name"
                class="purchases__item purchase"
              >
                <div class="purchase__cols">
                  <div class="purchase__title purchase__col">
                    <div class="purchase__icon">
                      <svg v-if="purchase.type == PurchaseType.SUBSCRIPTION">
                        <use
                          v-bind="{
                            'xlink:href':
                              require('@/assets/icons/all_icons.svg') +
                              '#calendar',
                          }"
                        />
                      </svg>
                      <svg v-else-if="purchase.type == PurchaseType.PRODUCT">
                        <use
                          v-bind="{
                            'xlink:href':
                              require('@/assets/icons/all_icons.svg') + '#once',
                          }"
                        />
                      </svg>
                    </div>
                    <span>
                      {{ getPurchaseName(purchase.purchase?.name) }}
                    </span>
                  </div>
                  <div class="purchase__col">
                    {{ PurchaseStatuses[purchase.status] }}
                  </div>
                  <div class="purchase__col">
                    {{ getPaymentTypeName(purchase.paymentType) }}
                  </div>
                  <div class="purchase__col purchase__col--small">
                    ${{ purchase.purchase?.amount }}
                  </div>
                  <div class="purchase__col">
                    {{ getFormattedDate(purchase.createdAt) }}
                  </div>
                </div>
                <div class="purchase__actions">
                  <button
                    class="purchase__action"
                    v-if="!isAddingPurchase"
                    @click="selectPurchaseToEdit(purchase)"
                  >
                    <svg>
                      <use
                        v-bind="{
                          'xlink:href':
                            require('@/assets/icons/all_icons.svg') + '#edit',
                        }"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="purchases__col" v-else>
              <div class="purchases__heading">
                <div class="purchase__title purchase__col">
                  <div class="purchase__icon">
                    <svg>
                      <use
                        v-bind="{
                          'xlink:href':
                            require('@/assets/icons/all_icons.svg') +
                            '#calendar',
                        }"
                      />
                    </svg>
                  </div>
                  <span> Покупки за указанный период не найдены </span>
                </div>
              </div>
            </div>
          </div>
          <div class="purchases__list" v-else>
            <div class="purchases__col">
              <div class="purchases__heading">
                <div class="purchase__title purchase__col">
                  <div class="purchase__icon">
                    <svg>
                      <use
                        v-bind="{
                          'xlink:href':
                            require('@/assets/icons/all_icons.svg') +
                            '#calendar',
                        }"
                      />
                    </svg>
                  </div>
                  <span> Подписки </span>
                </div>
              </div>
              <div
                v-for="purchase in Subscriptions"
                :key="purchase.name"
                :class="[
                  'purchases__item',
                  'purchase',
                  'purchase--selectable',
                  newPurchaseOptions.purchase?.name == purchase.name &&
                    'purchase--selected',
                ]"
                @click="
                  selectPurchaseToAdd(purchase, PurchaseType.SUBSCRIPTION)
                "
              >
                <div class="purchase__col">
                  {{ getPurchaseName(purchase.name) }}
                </div>
              </div>
            </div>
            <div class="purchases__col">
              <div class="purchases__heading">
                <div class="purchase__title purchase__col">
                  <div class="purchase__icon">
                    <svg>
                      <use
                        v-bind="{
                          'xlink:href':
                            require('@/assets/icons/all_icons.svg') + '#once',
                        }"
                      />
                    </svg>
                  </div>
                  <span> Продукты </span>
                </div>
              </div>
              <div
                v-for="purchase in products"
                :key="purchase.name"
                :class="[
                  'purchases__item',
                  'purchase',
                  'purchase--selectable',
                  newPurchaseOptions.purchase?.name == purchase.name &&
                    'purchase--selected',
                ]"
                @click="selectPurchaseToAdd(purchase, PurchaseType.PRODUCT)"
              >
                <div class="purchase__col">
                  {{ getPurchaseName(purchase.name) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Transition name="expand">
          <div
            class="purchases__block"
            v-if="isAddingPurchase"
            v-show="newPurchaseOptions.purchase"
          >
            <div class="user__header header">
              <div class="header__title">Подтвердите выдачу:</div>
            </div>
            <div class="purchases__confirmation confirmation">
              <div class="confirmation__info">
                <div class="confirmation__purchase purchase">
                  <div class="purchase__col">
                    {{ getPurchaseName(newPurchaseOptions.purchase?.name) }}
                  </div>
                </div>
                <div
                  class="confirmation__dates"
                  v-if="newPurchaseOptions.type == PurchaseType.SUBSCRIPTION"
                >
                  <span>с</span>
                  <Input
                    class="confirmation__input"
                    type="date"
                    v-model="newPurchaseOptions.startAt"
                    :min="minStartAt"
                  />
                  <span>по</span>
                  <Input
                    class="confirmation__input"
                    type="date"
                    v-model="newPurchaseOptions.expiresAt"
                    :min="minExpiresAt"
                    :max="maxExpiresAt"
                  />
                </div>
              </div>
              <Button class="header__action" blue @click="issueNewPurchase">
                Подтвердить
              </Button>
            </div>
          </div>
        </Transition>
        <Transition name="expand">
          <div
            class="purchases__block"
            v-if="isEditingPurchase"
            ref="editBlock"
          >
            <div class="user__header header">
              <div class="header__title">Подтвердите редактирование:</div>
            </div>
            <div class="purchases__confirmation confirmation">
              <div class="confirmation__info">
                <div class="confirmation__purchase purchase">
                  <div class="purchase__col">
                    {{
                      getPurchaseName(
                        editingPurchaseOptions.userPurchase?.purchase?.name
                      )
                    }}
                  </div>
                </div>
                <Select
                  class="confirmation__input"
                  :placeholder="'Статус покупки'"
                  :items="purchaseStatusesList"
                  v-model="editingPurchaseOptions.status"
                />
                <Select
                  class="confirmation__input"
                  :placeholder="'Тип оплаты'"
                  :items="paymentTypesList"
                  v-model="editingPurchaseOptions.paymentType"
                />
                <!-- <Input
                  class="confirmation__input"
                  placeholder="Имя пользователя"
                  v-model="editingPurchaseOptions.userName"
                /> -->
              </div>
              <div class="header__actions">
                <Button class="header__action" @click="cancelEditing" gray>
                  Отмена
                </Button>
                <Button class="header__action" blue @click="updateUserPurchase">
                  Сохранить
                </Button>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </Modal>
</template>

<script>
import Button from "./Button.vue";
import StatusChip from "./StatusChip.vue";
import Input from "./Input.vue";
import Modal from "./Modal.vue";
import Select from "./Select.vue";
import Table from "./Table.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import copyToClipboard from "@/utils/copyToClipboard";
import PurchaseType from "@/utils/PurchaseType";
import { mapActions, mapGetters } from "vuex";
import { Purchases, Subscriptions, PurchaseStatuses } from "@/utils/purchases";
import { mdiCancel, mdiArrowRight, mdiDelete } from "@mdi/js";

export default {
  name: "UserModal",
  components: {
    Modal,
    StatusChip,
    Button,
    Input,
    Select,
    Table,
    SvgIcon,
  },
  props: {
    ...Modal.props,
    user: Object,
  },
  data() {
    return {
      Purchases,
      PurchaseType,
      PurchaseStatuses,
      Subscriptions, // TODO: replace when /subscription route will work

      icons: {
        banned: mdiCancel,
        arrowRight: mdiArrowRight,
        delete: mdiDelete,
      },

      isBanning: false,

      isEditingPurchase: false,
      editingPurchaseOptions: {
        userPurchase: null,
        userName: "",
        status: "",
        paymentType: "",
      },

      isAddingPurchase: false,
      newPurchaseOptions: {
        type: null,
        purchase: null,
        startAt: "",
        expiresAt: "",
      },

      paymentTypesList: [
        {
          id: "PAY_PAL",
          name: "PayPal",
        },
        {
          id: "CRYPTO_CLOUD",
          name: "CryptoCloud",
        },
        {
          id: "ISSUED",
          name: "Выдано",
        },
      ],

      releases: [],
      releasesPagination: {
        options: {
          page: 1,
          take: 10,
          transfered: undefined,
          order: 'ASC'
        },
        meta: {},
      },
    };
  },
  mounted() {
    this.fetchUserReleasesRequest();
    this.fetchProducts();
    this.fetchSubscriptions();
  },
  methods: {
    copyToClipboard,

    ...mapActions([
      "fetchUserReleases",

      "issueUserProduct",
      "issueUserSubscription",
      "updateUserProduct",
      "updateUserSubscription",
      "fetchProducts",
      "fetchSubscriptions",

      "banUser",
      "unbanUser",
    ]),

    selectRelease (uuid) {
      this.$router.push({ name: 'Main', params: { release_uuid: uuid } })
    },

    copyReleaseURL(release){
      navigator.clipboard.writeText(`${window.location.origin}/main/${release.uuid}`);
    },

    fetchUserReleasesRequest() {
      const payload = {
        userUuid: this.user.uuid,
        options: this.releasesPagination.options,
      }

      this.fetchUserReleases(payload).then((response) => {
        this.releases = response.data.data;
        this.releasesPagination.meta = response.data.meta;
      });
    },

    ban() {
      const ban_reason = prompt("Введите причину блокировки");
      const { credentials_uuid } = this.user.profile;
      if (!ban_reason || !credentials_uuid) return;

      this.banUser({
        credentials_uuid,
        ban_reason,
      }).then(() => {
        this.isBanning = false;
      });
    },

    unban() {
      const { credentials_uuid } = this.user.profile;
      if (!credentials_uuid) return;

      this.unbanUser(credentials_uuid);
    },

    getPaymentTypeName(id) {
      const paymentType = this.paymentTypesList.find((p) => p.id == id);
      const name = paymentType?.name;
      return name ?? id;
    },

    getPurchaseName(purchaseName) {
      return Purchases[purchaseName]?.displayName ?? purchaseName;
    },

    updateUserPurchase() {
      this.updateUserPurchaseRequest().then(() => {
        this.cancelEditing();
      });
    },

    updateUserPurchaseRequest() {
      const uuid = this.editingPurchaseOptions.userPurchase.uuid;
      const data = {
        status: this.editingPurchaseOptions.status,
        paymentType: this.editingPurchaseOptions.paymentType,
      };

      const payload = {
        uuid,
        data,
      };

      switch (this.editingPurchaseOptions.userPurchase.type) {
        case PurchaseType.PRODUCT:
          return this.updateUserProduct(payload);
        case PurchaseType.SUBSCRIPTION:
          return this.updateUserSubscription(payload);
      }
    },

    issueNewPurchase() {
      this.issueNewPurchaseRequest().then(() => {
        this.toggleIsAddingPurchase();
      });
    },

    issueNewPurchaseRequest() {
      const userUuid = this.user.credentials_uuid;

      switch (this.newPurchaseOptions.type) {
        case PurchaseType.PRODUCT:
          return this.issueUserProductRequest(userUuid);
        case PurchaseType.SUBSCRIPTION:
          return this.issueUserSubscriptionRequest(userUuid);
      }
    },

    issueUserProductRequest(userUuid) {
      const productName = this.newPurchaseOptions.purchase.name;

      const payload = {
        userUuid,
        productName,
      };

      return this.issueUserProduct(payload);
    },

    issueUserSubscriptionRequest(userUuid) {
      const subscriptionName = this.newPurchaseOptions.purchase.name;

      const startAt = new Date(this.newPurchaseOptions.startAt).toISOString();
      const expiresAt = new Date(
        this.newPurchaseOptions.expiresAt
      ).toISOString();

      const payload = {
        userUuid,
        subscriptionName,
        startAt,
        expiresAt,
      };

      return this.issueUserSubscription(payload);
    },

    getFormattedDate(dateString) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString("ru-RU", options);
      return formattedDate;
    },

    closeUserModal() {
      this.$emit("close");
    },

    toggleIsAddingPurchase() {
      this.isAddingPurchase = !this.isAddingPurchase;
      this.newPurchaseOptions.purchase = null;

      this.isEditingPurchase = false;
    },

    selectPurchaseToAdd(purchase, type) {
      if (this.newPurchaseOptions.purchase?.name == purchase.name) {
        this.newPurchaseOptions.purchase = null;
      } else {
        this.newPurchaseOptions = {
          ...this.newPurchaseOptions,
          purchase,
          type,
        };
      }
    },

    selectPurchaseToEdit(purchase) {
      if (this.editingPurchaseOptions.userPurchase?.uuid == purchase.uuid) {
        this.cancelEditing();
      } else {
        this.editingPurchaseOptions.userPurchase = purchase;
        this.editingPurchaseOptions.status = purchase.status;
        this.editingPurchaseOptions.paymentType = purchase.paymentType;
        this.isEditingPurchase = true;
        this.$nextTick(() => {
          this.scrollToEditBlock();
        });
      }
    },

    cancelEditing() {
      this.isEditingPurchase = false;
      this.editingPurchaseOptions.userPurchase = null;
      this.editingPurchaseOptions.userName = "";
      this.scrollToTop();
    },

    scrollToEditBlock() {
      const element = this.$refs.editBlock;
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    },

    scrollToTop() {
      const element = this.$refs.user;
      element.scrollTop = 0;
    },
  },
  computed: {
    ...mapGetters(["file", "userPurchases", "products", "subscriptions"]),

    releasesList: function () {
      let releases = [...this.releases].map(release => {
        return {
          ...release,
          release_date: new Date(release.release_date).toLocaleDateString('ru-RU'),
          transfered_at: release.transfered_at ? new Date(release.transfered_at).toLocaleDateString('ru-RU') : null,
          artist: {
            name: "this.getArtist(release.artist_uuid) ? this.getArtist(release.artist_uuid).name : 'Artist'",
          },
          redirectUrl: `/drafts/${release.uuid}`,
        };
      });

      if (this.releasesPagination.options.status) {
        return  releases.filter(release => release?.status == this.releasesPagination.options.status)
      } else {
        return releases
      }
    },

    purchaseStatusesList() {
      return Object.entries(PurchaseStatuses).map(([key, value]) => ({
        id: key,
        name: value,
      }));
    },

    purchasesList() {
      const uuid = this.user?.credentials_uuid;
      const purchases = this.userPurchases(uuid).map((userPurchase) => {
        let purchase;
        switch (userPurchase.type) {
          case PurchaseType.SUBSCRIPTION:
            purchase = userPurchase.subscription;
            break;
          case PurchaseType.PRODUCT:
            purchase = userPurchase.product;
            break;
        }

        return {
          ...userPurchase,
          purchase,
        };
      });
      return purchases;
    },

    isUserBanned() {
      return this.user?.ban_reason !== null && this.user?.role == "banned";
    },

    userName() {
      const user = this.user;
      if (!user?.profile) return;

      const { first_name, last_name } = user?.profile;

      if (first_name || last_name) {
        return [first_name, last_name].join(" ");
      }
      return "Без имени";
    },

    minStartAt() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().substr(0, 10);
    },

    maxExpiresAt() {
      const nextYear = new Date();
      nextYear.setFullYear(nextYear.getFullYear() + 1);
      return nextYear.toISOString().substr(0, 10);
    },

    maxStartAt() {
      if (this.newPurchaseOptions.expiresAt) {
        const maxStart = new Date(this.newPurchaseOptions.expiresAt);
        maxStart.setDate(maxStart.getDate() - 1);
        return maxStart.toISOString().substr(0, 10);
      } else {
        const maxStart = new Date(this.maxExpiresAt);
        maxStart.setDate(maxStart.getDate() - 1);
        return maxStart.toISOString().substr(0, 10);
      }
    },

    minExpiresAt() {
      if (this.newPurchaseOptions.startAt) {
        const minExpire = new Date(this.newPurchaseOptions.startAt);
        minExpire.setDate(minExpire.getDate() + 1);
        return minExpire.toISOString().substr(0, 10);
      } else {
        const minExpire = new Date(this.minStartAt);
        minExpire.setDate(minExpire.getDate() + 1);
        return minExpire.toISOString().substr(0, 10);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";

@keyframes expandAnimation {
  from {
    max-height: 0;
  }
  to {
    max-height: 100px;
  }
}

@keyframes collapseAnimation {
  from {
    max-height: 100px;
  }
  to {
    max-height: 0;
  }
}

.expand-enter-active {
  animation: expandAnimation 0.4s forwards;
  overflow: hidden;
}
.expand-leave-active {
  overflow: hidden;
  animation: collapseAnimation 0.3s forwards;
}

.user {
  display: flex;
  width: 1000px;
  padding: 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 9px;
  background: #000;
  max-height: 80vh;
  overflow-y: auto;
  scroll-behavior: smooth;

  &__main {
    width: 100%;
  }

  &__releases {
    width: 100%;
  }

  &__purchases {
  }

  &__header {
  }
}
.main {
  display: flex;
  align-items: center;
  gap: 24px;

  &__avatar {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: #d9d9d9;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: inherit;
      object-fit: cover;
      aspect-ratio: 1;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  &__name {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__ban {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      color: $red;
      margin-bottom: 1px;
    }
  }

  &__copy {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    background: none;
    cursor: copy;
    border-radius: 4px;
    transition: 0.2s;

    svg {
      width: 21px;
      height: 21px;
      fill: #8d8d8d;
    }

    &:hover {
      transition: 0.2s;
      background: #454545;
    }
  }

  &__email {
    display: flex;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__verification {
    margin-left: -8px;
  }

  &__actions {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
.verification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;

  &__icon {
    width: 13px;
    height: 13px;

    &--verified {
      fill: #ed2224;
    }

    &--unverified {
      fill: #4e4e4e;
    }
  }
}
.purchases {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  &__list {
    max-height: 240px;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
  }

  &__heading {
    display: flex;
    height: 40px;
    padding: 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 9px;
    background: #262626;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 100%; /* 16px */
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;
  }

  &__item {
  }

  &__confirmation {
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  min-height: 36px;

  &__title {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }

  &__actions {
    display: flex;
    gap: 16px;
  }

  &__action {
  }
}
.purchase {
  display: flex;
  height: 40px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 9px;
  background: #3f4146;
  transition: 0.2s;

  &__cols {
    display: flex;
    flex: 1;
    padding-right: 16px;
  }

  &__col {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 100%; /* 16px */
    white-space: nowrap;
    flex: 1 0 0;

    &--small {
      flex: 0.4;
    }

    &:last-child {
      flex: 0;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1.3;
  }

  &__icon {
    max-width: 16px;
    max-height: 14px;

    svg {
      width: 16px;
      height: 14px;
      fill: #a8a8a8;
    }
  }

  &__actions {
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    width: 15px;
    height: 15px;

    svg {
      transition: 0.2s;
      width: 11px;
      height: 11px;
      fill: #bababa;
    }

    &:hover {
      svg {
        transform: scale(1.2);
        transition: 0.15s;
        fill: #9c9c9c;
      }
    }
  }

  &--selectable {
    cursor: pointer;
  }

  &--selected {
    transition: 0.2s;
    background: #2a5cdb;
  }
}
.confirmation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 16px;

  &__info {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__purchase {
  }

  &__dates {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__input {
    height: 40px;
  }
}
</style>
