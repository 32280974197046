<template>
    <Modal v-show="showLogoutModal" name="scale">
        <div class="modal">
            <h2>Вы действительно хотите выйти?</h2>

            <div class="actions">
                <Button @click="logoutRequest">Да</Button>
                <Button @click="showLogoutModal = false" gray>Нет</Button>
            </div>
        </div>
    </Modal>
    <header class="header">

        <img src="@/assets/Logo.svg" class="logo" alt="">

        <ul class="nav" @click="showLogoutModal=false">
            <li :class="['nav-item', {active:$route.name == 'Main'}]">
                <router-link :to="{name:'Main'}">Релизы</router-link>
            </li>
            <li :class="['nav-item', {active:$route.name == 'Users'}]">
                <router-link :to="{name:'Users'}">Пользователи</router-link>
            </li>
            <li :class="['nav-item', {active:$route.name == 'Survey'}]">
                <router-link :to="{name:'Survey'}">Опросы</router-link>
            </li>
        </ul>

        <button class="logout" @click="showLogoutModal = true">
            <svg viewBox="0 0 24 24">
                <g id="grid_system"/><g id="_icons"><g><path d="M20.9,11.6c-0.1-0.1-0.1-0.2-0.2-0.3l-3-3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l1.3,1.3H13c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.6    l-1.3,1.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0.1-0.1,0.2-0.2,0.2-0.3C21,12.1,21,11.9,20.9,11.6z    "/><path d="M15.5,18.1C14.4,18.7,13.2,19,12,19c-3.9,0-7-3.1-7-7s3.1-7,7-7c1.2,0,2.4,0.3,3.5,0.9c0.5,0.3,1.1,0.1,1.4-0.4    c0.3-0.5,0.1-1.1-0.4-1.4C15.1,3.4,13.6,3,12,3c-5,0-9,4-9,9s4,9,9,9c1.6,0,3.1-0.4,4.5-1.2c0.5-0.3,0.6-0.9,0.4-1.4    C16.6,18,16,17.8,15.5,18.1z"/></g></g>
            </svg> 
        </button>
    </header>
</template>
<script>
import {mapActions} from 'vuex'
import Button from "@/components/Button.vue"
import Modal from '@/components/Modal.vue'

export default {
    components:{
        Button,
        Modal
    },

    data(){
        return{
            showLogoutModal: false
        }
    },

    methods:{
        ...mapActions(['logout']),

        logoutRequest(){
            this.logout()
            this.$router.push({name:'Welcome'})
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '@/assets/_shared.scss';

    .header{
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .logo{
            position: absolute;
            height: 30px;
            margin-right: 70px;
            left: 0;
        }

        .nav{
            display: flex;
            padding: 0;
            margin: 0;
            list-style: none;

            .nav-item{
                padding-top: 7px;
                margin-right: 30px;
                cursor: pointer;

                

                &:last-child{
                    margin: 0;
                }

                a{
                    font-size: 20px;
                    text-transform: capitalize;
                    user-select: none;
                    text-decoration: none;
                    text-transform: capitalize;
                    line-height: normal;
                    color: #bbbbbb;


                    &:hover{
                        color: white;
                    }
                }
            }

            .active{
                position: relative;
                
                a{color: white;}
                
                &::after{
                    content: '';
                    position: absolute;
                    width: 5px;
                    height: 5px;
                    background: $red;
                    border-radius: 100%;
                    top: -5px;
                    left: calc(50% - 2px);
                }
            }
        }

        .logout{
            background: transparent;
            padding: 5px;
            border:none;
            position: absolute;
            right: 0;
            cursor: pointer;
            
            svg{
                width: 30px;
                width: 30px;
                fill: #bbbbbb;
                transition: .3s;
            }

            &:hover svg{
                fill: white;
            }
        }
    }

    

    .modal{
        z-index: 9999;
        border-radius: 9px;
        padding: 20px 40px;
        width: 480px;
        height: min-content;
        background: rgba(#1f2023, 0.8);
        backdrop-filter: blur(12px);

        .actions{
            display: flex;

            button{
                margin-right: 20px;
            }
        }

        h2{
            font-size: 27px;
            color: white;
            margin-bottom: 30px;
        }
    }
</style>